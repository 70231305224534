import { memo, useEffect, useRef, useState } from 'react';
import { notification, Skeleton } from 'antd';
import LogoCampaign from '../../../../../assets/images/profile.png';
import './styles.scss';
import { useSetPhotoUrl } from '../../../../../hooks';
import Icon from '../../../../atoms/Icon';
import ImageProfile from '../../../../atoms/ImageProfile';
import { useUserContex } from '../../../../../context/User';

const SideBarUser = ({
  isLoading,
  userPhoto,
  userName,
  userType,
}: {
  isLoading: boolean;
  userPhoto?: string | null;
  userName?: string;
  userType: 'BRANDS' | 'PROMOTER';
}) => {
  const [imgUrl, setImgUrl] = useState<string | null>();
  const [isUploading, urlReload, setPhotoUrl] = useSetPhotoUrl();
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user, getUserData } = useUserContex()!;

  useEffect(() => {
    if (urlReload) {
      setImgUrl(urlReload);
      if (user.data?.uid) {
        getUserData(user.data?.uid);
      }
    }
  }, [urlReload]);

  useEffect(() => {
    setImgUrl(userPhoto);
  }, [userPhoto]);

  const imageClick = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;
    if (file.size / 1048576 > 5) {
      notification['warning']({
        message: 'File upload failed',
        description: 'The file size can not exceed 5 MB',
      });
      return;
    }
    setPhotoUrl(file);
  };

  return (
    <div className='SideBarUser'>
      {isLoading || isUploading ? (
        <div className='mb-3'>
          <Skeleton.Avatar active size={87} shape='circle' />
        </div>
      ) : (
        <div className='user-photo-wrapper'>
          <input
            accept='image/*'
            ref={fileRef}
            onChange={imageClick}
            multiple={false}
            type='file'
            hidden
          />
          <ImageProfile
            className='user-photo'
            src={imgUrl ? imgUrl : LogoCampaign}
          />
          <div
            className='user-upload-photo'
            onClick={() => fileRef.current?.click()}
          >
            <Icon name={'edit-pencil'} fill='dark'></Icon>
          </div>
        </div>
      )}

      {isLoading ? (
        <Skeleton.Input
          active
          size='large'
          style={{ width: 100, height: 20 }}
        />
      ) : (
        <span className='user-name'>{userName}</span>
      )}
    </div>
  );
};

export default memo(SideBarUser);

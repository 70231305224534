import {
  ReactNode,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
/* Firebase */
import { auth } from '../credentials/base';
import { openNotification } from '../utils';
/* Hooks */
import { useLogger, useLocalStorage } from '../hooks';
/* Services */
import {
  getUserData as serviceGetUserData,
  createUser,
} from '../services/user';
/* Types */
import { LoginUser, UserContextType, UserData, User } from '../types/user';
/* Constants */
import { LOCAL_STORAGE_KEY } from '../constants';
import { joinCampaign } from '../services/promoter';

const Context = createContext<UserContextType | undefined>(undefined);
const { Provider, Consumer } = Context;

const UserProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [, setStoredUser] = useLocalStorage(LOCAL_STORAGE_KEY, {});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>({
    authenticated: false,
    data: null,
  });
  useLogger({ user, isLoading });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      const loadData = async () => {
        if (firebaseUser) {
          const token = await firebaseUser.getIdToken();
          if (token && firebaseUser.uid) {
            getUserData(firebaseUser.uid);
            setStoredUser({
              uid: firebaseUser.uid,
              token,
              refreshToken: firebaseUser.refreshToken,
            });
          }
        }
      };
      loadData();
    });

    return unsubscribe;
  }, []);

  const login = (userData: LoginUser): void => {
    setIsLoading(true);
    const initSession = async () => {
      await auth.signInWithEmailAndPassword(userData.email, userData.password);
    };
    initSession().catch((error) => {
      setIsLoading(false);
      console.log('catched Login output', error);
      if (error.code === 'auth/wrong-password') {
        openNotification('error', 'Sign in', 'User credential are not valid');
      }
    });
  };

  const logout = async (): Promise<void> => {
    await auth.signOut();
    setUser({
      data: null,
      authenticated: false,
    });
    setStoredUser(null);
    window.location.replace('/login');
  };

  const getUserData = useCallback(
    async (uid: string) => {
      setIsLoading(true);
      const userData = await serviceGetUserData(uid);
      console.log(userData);
      setUser({
        data: {
          uid: userData?.user.uid,
          displayName: userData?.user.displayName,
          email: userData?.user.email,
          role: userData?.user.role,
          photoURL: auth.currentUser?.photoURL,
          phoneNumber: '',
          website: userData?.user.website,
          userExtras: userData?.userExtras,
        },
        authenticated: true,
      });
      setIsLoading(false);
    },
    [setUser]
  );

  const signUp = useCallback(async (values: UserData, id?: string) => {
    const userCreatedConfirm = await createUser(values);
    if (userCreatedConfirm) {
      await auth.signInWithCustomToken(userCreatedConfirm.customToken);
      openNotification('success', 'Sign up', 'User created successfully');
      if (id) {
        joinCampaign(id)
          .then((response) => {
            if (!response.error) {
              openNotification(
                'success',
                'Join Campaign',
                'You were joined to the campaign successfully'
              );
            } else {
              openNotification(
                'error',
                'Join Campaign',
                "Couldn't be joined. An error has occurred: " + response.message
              );
            }
          })
          .catch((error) => {
            console.log(error);
            openNotification(
              'error',
              'Join Campaign',
              "Couldn't be joined. An error has occurred: " + error.message
            );
          });
      }
      // setTimeout(() => {
      //   window.location.replace('//campaigns');
      // }, 1500);
    }
  }, []);

  const value: UserContextType = {
    user,
    isLoading,
    getUserData,
    login,
    logout,
    signUp,
    setIsLoading,
  };

  return <Provider value={value}>{children}</Provider>;
};

const useUserContex = (): UserContextType => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { UserProvider, Consumer, Context, useUserContex };

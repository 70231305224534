import { useState, memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Row,
  Col,
  AutoComplete,
  Checkbox,
} from 'antd';
/* Services */
import { getCities } from '../../../services/campaign';
import { paymentAttempt } from '../../../services/stripe';

/* Types */
import { City, CityAutoCompleteDataSet } from '../../../types/city';

import { openNotification } from '../../../utils';
import './styles.scss';
import StripeCheckoutForm from '../StripeCheckoutForm';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const calculateCampaignFunds = (budget: number): number =>
  Math.round(((budget - 0.3) / 1.1) * 100) / 100;

const WordOfMouthCampaignForm = ({
  onSuccessSubmit,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccessSubmit: (values: any, callBack: (res: any) => void) => void;
}): JSX.Element => {
  const [options, setOptions] = useState<Array<CityAutoCompleteDataSet>>([]);
  const [cityQuery, setCityQuery] = useState<string>('');
  const [latCoord, setLatCoord] = useState<string>('');
  const [lngCoord, setLngCoord] = useState<string>('');
  const [value, setValue] = useState<number>(0);
  const [clientSecret, setClientSecret] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [stripeFormVisible, setstripeFormVisible] = useState<boolean>(true);
  const [validForm, setValidForm] = useState<boolean>(false);
  const [overallBudget, setOverallBudget] = useState<number>(0);
  const history = useHistory();
  const onButtonStatusChange = (status: boolean) => {
    setButtonEnabled(status);
  };

  const [form] = Form.useForm();

  const onFinish = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: any): void => {
      setIsProcessing(true);
      onSuccessSubmit(
        {
          ...form.getFieldsValue(),
          lat: latCoord,
          lng: lngCoord,
        },
        (res: any) => {
          if (res.cid) {
            console.log('VALUE', value);
            paymentAttempt(value, res.cid).then((response) => {
              setClientSecret(response ? response.clientSecret : '');
              setTimeout(() => {
                console.log('pushing campaings');
                history.push({
                  pathname: '/campaigns',
                  state: {
                    tab: 'pause',
                  },
                });
              }, 500);
            });
          }
        }
      );
    },
    [latCoord, lngCoord]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = useCallback((errorInfo: any): void => {
    console.log('Failed:', errorInfo);
    setIsProcessing(false);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelect = (val: string, option: any) => {
    setLatCoord(option.lat);
    setLngCoord(option.lng);
  };

  const onMakePublicClicked = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({
      makePublic: e.target.checked,
    });
  };

  const onSearch = (val: string) => {
    setCityQuery(val);
  };

  const handleBudgetChange = (event: number) => {
    setValue(event);
    setOverallBudget(event);
    if (form.getFieldValue('scanLimit') > event) {
      form.setFieldsValue({
        scanLimit: event,
      });
    }
  };

  const handlePaymentSuccess = (displayNotification = true) => {
    if (displayNotification) {
      openNotification(
        'success',
        'Payment Success',
        'Your payment was received.'
      );
    }
  };

  const handlePaymentFailed = () => {
    setIsProcessing(false);
    openNotification('error', 'Payment fail', 'Your payment has failed');
    console.log('fail payment');
    setstripeFormVisible(false);
    setTimeout(() => {
      setstripeFormVisible(true);
    }, 300);
  };

  const formValuesChange = () => {
    if (
      form.isFieldTouched('title') &&
      form.isFieldTouched('landingPage') &&
      form.isFieldTouched('budget') &&
      form.isFieldTouched('startDate') &&
      form.isFieldTouched('endDate') &&
      form.isFieldTouched('description') &&
      form.isFieldTouched('scanLimit') &&
      form.isFieldTouched('city') &&
      form.isFieldTouched('mileRadius')
    ) {
      form
        .validateFields()
        .then(() => {
          setValidForm(true);
        })
        .catch((eve) => {
          if (eve.errorFields.length) {
            setValidForm(false);
          } else {
            setValidForm(true);
          }
        });
    } else {
      setValidForm(false);
    }
  };

  useEffect(() => {
    (async () => {
      const cities: Array<City> = await getCities(cityQuery);
      if (cities) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const autocompleteDataset = cities.map((city: any) => {
          return {
            label: city.city + ', ' + city.admin_name,
            value: city.city + ', ' + city.admin_name,
            lat: city.lat,
            lng: city.lng,
            key: city.id,
          };
        });

        setOptions([...autocompleteDataset]);
      }
    })();
  }, [cityQuery]);

  return (
    <Form
      className='WOMCampaignForm'
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={formValuesChange}
      form={form}
      initialValues={{
        landingPage: 'https://sendcustomerhere.com',
        makePublic: true,
      }}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name='title'
            rules={[
              {
                required: true,
                message: 'Please enter the campaign title',
              },
              { type: 'string', message: 'Please, enter a valid title!' },
            ]}
          >
            <Input placeholder='Campaign Title' />
          </Form.Item>
        </Col>
      </Row>
      <Row className='makePublicRow'>
        <Col span={18}>
          <Form.Item
            name='landingPage'
            rules={[
              {
                required: true,
                message: 'Please enter a existing website Landing page URL',
              },
              { type: 'url', message: 'Please, enter a valid URL!' },
            ]}
          >
            <Input placeholder='https://sendcustomerhere.com' />
          </Form.Item>
        </Col>
        <Col span={4}>
          <h3>Make Public</h3>
        </Col>
        <Col span={2}>
          <Form.Item name='makePublic'>
            <Checkbox defaultChecked={true} onChange={onMakePublicClicked} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name='budget'
            rules={[
              { required: true, message: 'Please define a overall budget' },
              { type: 'integer', message: 'Please, enter a valid budget!' },
            ]}
          >
            <InputNumber
              min={1}
              step={0.01}
              placeholder='Overall Budget'
              onChange={handleBudgetChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name='startDate'
            rules={[
              { required: true, message: 'Please enter the starting date!' },
              { type: 'date', message: 'Please, enter a valid date!' },
            ]}
          >
            <DatePicker
              placeholder='Start Date'
              disabledDate={(current) => {
                // Can not select days before today
                return current && current < moment().endOf('day');
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name='endDate'
            rules={[
              { required: true, message: 'Please enter the ending date!' },
              { type: 'date', message: 'Please, enter a valid date!' },
            ]}
          >
            <DatePicker
              placeholder='End Date'
              disabledDate={(current) => {
                // Can not select days before today
                return (
                  current &&
                  current < moment(form.getFieldValue('startDate')).endOf('day')
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name='description'
            rules={[
              {
                required: true,
                message: 'Please enter a description',
              },
              { max: 99, message: 'Max 100 characters.' },
            ]}
          >
            <Input
              placeholder='Add Description. Max 100 characters.'
              maxLength={100}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className='ant-form-item'>
            <h3>Pay People Not Search Engines</h3>
            <p>
              Fee %10.0 + 30 cents:
              <strong>
                {overallBudget
                  ? ` $${(
                      overallBudget - calculateCampaignFunds(overallBudget)
                    ).toFixed(2)}`
                  : '$0'}
              </strong>
            </p>
            <p>
              Campaign funds:
              <strong>
                {overallBudget
                  ? ` $${calculateCampaignFunds(overallBudget)}`
                  : '$0'}
              </strong>
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name='scanLimit'
            rules={[
              { required: true, message: 'Please enter the scan limit!' },
              { type: 'number', message: 'Please, enter a valid number!' },
            ]}
          >
            <InputNumber
              min={0.01}
              max={overallBudget}
              step={0.01}
              prefix='$'
              placeholder='Per Click Payment'
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={17}>
          <Form.Item
            name='city'
            rules={[
              { required: true, message: 'Please input city or region!' },
              { type: 'string', message: 'Please, enter a valid city!' },
            ]}
          >
            <AutoComplete
              options={options}
              onSelect={onSelect}
              onSearch={onSearch}
              placeholder='Cities, Regions'
              /*getPopupContainer={() => document.getElementById('wrapper')}*/
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={7}>
          <Form.Item
            name='mileRadius'
            rules={[
              { required: true, message: 'Please enter mile radius!' },
              { type: 'number', message: 'Please, enter a valid number!' },
            ]}
          >
            <InputNumber placeholder='Mile Radius' />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item className='text-center m-0'>
        {stripeFormVisible && (
          <>
            <StripeCheckoutForm
              onStatusChange={(status) => {
                onButtonStatusChange(status);
              }}
              value={value}
              cliSecret={clientSecret}
              onPaymentFailed={handlePaymentFailed}
              onPaymentSucceed={handlePaymentSuccess}
            />{' '}
            <div
              style={{
                fontSize: '14px',
                marginBottom: '3px',
                marginTop: '5px',
                float: 'left',
              }}
            >
              Card pre-authorized, weekly charges on Friday up to budget amount.
            </div>
          </>
        )}{' '}
      </Form.Item>
      {isProcessing && <div className='text-center'>Processing...</div>}
      <Form.Item className='text-center m-0'>
        <Button
          disabled={!validForm || !buttonEnabled}
          type='primary'
          htmlType='submit'
        >
          Pay & Create Campaign
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(WordOfMouthCampaignForm);

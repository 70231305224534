import { memo, useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Header from '../../organisms/Header';
import SignUpForm from '../../organisms/SignUpForm';
import PolicyModal from '../../organisms/Modals/PolicyModal';
import LandingTemplate from '../../templates/LandingTemplate';

import { useUserContex } from '../../../context/User';
import { useStateRef } from '../../../hooks';
/* Files */
import { UserData } from '../../../types/user';

import './styles.scss';
import SplashScreen from '../../organisms/SplashScreen';

const SignUp = ({ isbusiness = false }): JSX.Element => {
  const [policyModalVisibility, setPolicyModalVisibility] =
    useState<boolean>(false);
  const [, setSignUpFormData, signUpFormDataCurrent] = useStateRef({});
  const [, setSignUpType, signUpTypeCurrent] = useStateRef('promoter');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user, signUp, isLoading } = useUserContex()!;
  const [title, setTitle] = useState('Promoter Sign UP');
  const [subtitle, setSubtitle] = useState(
    'As a promoter, you can join campaigns and earn money Etc.'
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isbusiness) {
      setSignUpType('business');
      setTitle('Brand Sign Up');
      setSubtitle('As a brand, you can post campaigns and earn money Etc.');
    }
  }, []);

  useEffect(() => {
    if (user.authenticated) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { from }: any = location.state || {
        from: {
          pathname:
            user.data?.role == 'business' ? '/campaigns/new' : '/campaigns',
          state: user.data?.role == 'promoter' && {
            tab: 'my-campaign',
          },
        },
      };
      history.replace(from);
    }
  }, [user]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFormData = useCallback(
    (values: UserData) => {
      values.role = signUpTypeCurrent.current;
      setSignUpFormData({ role: signUpTypeCurrent.current, ...values });
      setPolicyModalVisibility(true);
    },
    [setSignUpFormData, setPolicyModalVisibility, signUpTypeCurrent.current]
  );

  const policyModalConfirmation = () => {
    setPolicyModalVisibility(false);
    console.log('signUpFormData', signUpFormDataCurrent.current);
    const state: any = location.state;
    signUp(signUpFormDataCurrent.current, state?.id);
  };

  const policyModalDismiss = () => {
    setPolicyModalVisibility(false);
  };

  return (
    <div className='SignUp'>
      <LandingTemplate
        header={<Header type='sign-up' />}
        mainContent={
          <div className='SignUp-container'>
            {isLoading && (
              <div style={{ marginTop: '80px' }}>
                <SplashScreen />
              </div>
            )}

            {!isLoading && (
              <>
                <h2>{title}</h2>
                <div className='SignUp-subtitle'>{subtitle}</div>
                <SignUpForm
                  type={signUpTypeCurrent.current}
                  getFormData={getFormData}
                />
              </>
            )}

            <PolicyModal
              modalVisibility={policyModalVisibility}
              onModalConfirm={policyModalConfirmation}
              onModalDismiss={policyModalDismiss}
            />
          </div>
        }
      />
    </div>
  );
};

export default memo(SignUp);

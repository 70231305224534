import { memo } from 'react';
import { Col, Row } from 'antd';
/* Compoenents */
import MenuHeader from '../../molecules/MenuHeader';
/* Files */
import Logo from '../../atoms/Logo';
/* Styles */
import './styles.scss';

const Header = ({
  logoType = 'default',
  type = 'default',
  aling = 'middle',
}: {
  logoType?: string;
  type?: 'default' | 'sign-up' | 'onlyLogo' | undefined;
  aling?: 'top' | 'middle' | 'bottom' | 'stretch' | undefined;
}): JSX.Element => {
  return (
    <div className={type == 'default' ? 'container' : 'Header-container'}>
      <Row className='Header' align={aling}>
        <Col span={12}>
          <Logo type={logoType} />
        </Col>
        {type != 'onlyLogo' && (
          <Col span={12}>
            <MenuHeader type={type} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default memo(Header);

import { memo, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import moment from 'moment';
import { openNotification } from '../../../utils';
/* Content */
import { useCampaignProvider } from '../../../context/Campaigns';
/* Services */
import { updateCampainStatus } from '../../../services/campaign';
import { joinCampaign, unjoinCampaign } from '../../../services/promoter';
/* Styles */
import './styles.scss';
/* Resources */
import ImageProfile from '../../atoms/ImageProfile';
import HeaderContent from './HeaderContent';

interface CampaignProps {
  id: string;
  isBusinessUser: boolean;
  isPromoterUser?: boolean;
  campaignType: string;
  status: string;
  isJoined: boolean;
  photoURL: string | undefined;
  title: string;
  businessName: string;
  cost: number;
  budget: number;
  spent: number;
  clicks: number;
  description: string;
  start_date: string;
  end_date: string;
  scanLimit: number;
  payment: number;
  dailyPay: number;
  total: number;
  style?: React.CSSProperties;
  onJoin: () => void;
  onShowQR: () => void;
  setIsJoined?: (bool: boolean) => void;
  setCampaignType?: (type: string) => void;
}

const CampaignCard = ({
  id,
  isBusinessUser,
  isPromoterUser,
  campaignType,
  status,
  isJoined,
  photoURL,
  title,
  businessName,
  budget,
  spent,
  clicks,
  description,
  start_date,
  end_date,
  dailyPay,
  total,
  scanLimit,
  style,
  onJoin,
  onShowQR,
  setIsJoined,
  setCampaignType,
}: CampaignProps): JSX.Element => {
  const { updateCampain } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useCampaignProvider()!;
  const [localStatus, setLocalStatus] = useState(status);
  const [joinedStatus, setjoinedStatus] = useState(isJoined);
  const [localCampaignType, setLocalCampaignType] = useState(campaignType);
  const [visible, setVisible] = useState(true);
  const date = parseDate(start_date, end_date, isBusinessUser);
  const history = useHistory();

  useEffect(() => {
    setLocalStatus(status);
    setjoinedStatus(isJoined);
    setLocalCampaignType(campaignType);
  }, [status, isJoined, campaignType]);

  const handleJoin = useCallback((): void => {
    if (
      !(
        localCampaignType == 'my-campaigns' ||
        joinedStatus ||
        localCampaignType == 'share-joined'
      )
    ) {
      joinCampaign(id)
        .then((response) => {
          if (!response.error) {
            setjoinedStatus(true);
            setVisible(false);
            if (setIsJoined && setCampaignType) {
              setCampaignType('share-joined');
              setIsJoined(true);
              setVisible(true);
            }
            openNotification(
              'success',
              'Join Campaign',
              'You were joined to the campaign successfully'
            );
            onJoin();
          } else {
            openNotification(
              'error',
              'Join Campaign',
              "Couldn't be joined. An error has occurred: " + response.message
            );
          }
        })
        .catch((error) => {
          console.log(error);
          openNotification(
            'error',
            'Join Campaign',
            "Couldn't be joined. An error has occurred: " + error.message
          );
        });
    }
  }, [id, localCampaignType, joinedStatus, setjoinedStatus]);

  const handleUnjoin = useCallback((): void => {
    unjoinCampaign(id)
      .then((response) => {
        if (!response.error) {
          setjoinedStatus(false);
          setVisible(false);
          if (setIsJoined && setCampaignType) {
            setCampaignType('share-not-joined');
            setIsJoined(false);
            setVisible(true);
          }
          openNotification(
            'success',
            'Unjoin Campaign',
            'You were unjoined from the campaign successfully'
          );
        } else {
          openNotification(
            'error',
            'Join Campaign',
            "Couldn't be unjoined. An error has occurred: " + response.message
          );
        }
      })
      .catch((error) => {
        console.log(error);
        openNotification(
          'error',
          'Join Campaign',
          "Couldn't be unjoined. An error has occurred: " + error.message
        );
      });
  }, [id, setjoinedStatus]);

  const handleStart = useCallback((): void => {
    const newStatus = localStatus == 'play' ? 'pause' : 'play';
    updateCampainStatus(id, newStatus).then(() => {
      if (updateCampain) {
        updateCampain(id, newStatus);
      }
    });
  }, [id, localStatus]);

  const handleShowQR = useCallback((): void => {
    onShowQR();
  }, [onShowQR]);

  const handleLanding = useCallback((): void => {
    console.log('test');
    if (isPromoterUser) {
      console.log('PROMOTER');
      joinCampaign(id)
        .then((response) => {
          if (!response.error) {
            openNotification(
              'success',
              'Join Campaign',
              'You were joined to the campaign successfully'
            );
            history.push({
              pathname: '/campaigns',
              state: {
                tab: 'my-campaigns',
              },
            });
          } else {
            openNotification(
              'error',
              'Join Campaign',
              "Couldn't be joined. An error has occurred: " + response.message
            );
          }
        })
        .catch((error) => {
          console.log(error);
          openNotification(
            'error',
            'Join Campaign',
            "Couldn't be joined. An error has occurred: " + error.message
          );
        });
    } else if (isBusinessUser) {
      history.push('/sign-up');
    } else {
      history.push({
        pathname: '/sign-up',
        state: {
          id: id,
        },
      });
    }
  }, [isBusinessUser, isPromoterUser]);

  const getCampaignActionButton = useCallback((): JSX.Element => {
    if (localCampaignType == 'expired' || localCampaignType == 'my-expired') {
      return <></>;
    }
    const campaignActionButton: JSX.Element = (
      <Button
        block
        type='primary'
        className={
          isBusinessUser
            ? localStatus === 'play'
              ? 'Campaign-btn w-100'
              : 'Campaign-btn action w-100'
            : localCampaignType == 'my-campaigns' ||
              localCampaignType == 'all' ||
              localCampaignType == 'share-joined'
            ? 'Campaign-btn'
            : joinedStatus
            ? 'Campaign-btn action w-100 disabled lock'
            : 'Campaign-btn action'
        }
        size='small'
        onClick={
          isBusinessUser
            ? handleStart
            : localCampaignType == 'my-campaigns' ||
              localCampaignType == 'share-joined'
            ? handleShowQR
            : localCampaignType == 'all'
            ? handleLanding
            : handleJoin
        }
      >
        <span>
          {isBusinessUser
            ? localStatus == 'pause'
              ? 'Start'
              : 'Stop'
            : localCampaignType == 'my-campaigns' ||
              localCampaignType == 'all' ||
              localCampaignType == 'share-joined'
            ? 'Share to earn money'
            : joinedStatus
            ? 'Joined'
            : 'Join'}
        </span>
      </Button>
    );

    return campaignActionButton;
  }, [joinedStatus, isPromoterUser, isBusinessUser]);

  return (
    <div className={'CampaignCard' + (visible ? '' : ' hidden')} style={style}>
      <div className='CampaignCard-side'>
        <div className='title'>{businessName}</div>
        <div className='logo'>
          <ImageProfile
            className='Campaign-logo'
            isBusinessUser={isBusinessUser}
            src={photoURL}
          />
        </div>
        <div>
          <Col span={24}>{getCampaignActionButton()}</Col>
        </div>
      </div>
      <div className='Campaign-content'>
        <HeaderContent
          campaignType={localCampaignType}
          isBusinessUser={isBusinessUser}
          id={id}
          onShowQR={onShowQR}
          handleUnjoin={handleUnjoin}
          setVisible={setVisible}
        />
        <div className='Campaign-title black'>{title}</div>

        {isBusinessUser && (
          <Row justify='space-between'>
            <Col className='info-business'>
              <p className='Campaign-label black'>Budget</p>
              <p className='Campaign-value action'>${budget}</p>
            </Col>
            <Col className='info-business'>
              <p className='Campaign-label black'>Spent</p>
              <p className='Campaign-value action'>${spent.toFixed(2)}</p>
            </Col>
            <Col className='info-business'>
              <p className='Campaign-label black'>Clicks</p>
              <p className='Campaign-value action'>{clicks.toFixed(0)}</p>
            </Col>
            <Col className='info-business-last'>
              <div className='Campaign-label black'>Duration</div>
              <div className='Campaign-dates-business black'>{date}</div>
            </Col>
          </Row>
        )}
        {!isBusinessUser && (
          <Row>
            <Col xs={24} sm={12} className='Campaign-values'>
              <div className='Campaign-label black'>Pay Per Click</div>
              <div className='Campaign-value action'>${scanLimit}</div>
            </Col>
            <Col xs={24} sm={12} className='Campaign-values'>
              <div className='Campaign-label black'>Duration</div>
              <div className='Campaign-dates black'>{date}</div>
            </Col>
          </Row>
        )}

        <div className='Campaign-description-container'>
          {description && (
            <>
              <div className='Campaign-label black'>Description</div>
              <div className='Campaign-description black'>{description}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Parse the start and end date according to the design
 * @param start_date
 * @param end_date
 * @returns parsed date according to the design
 */
function parseDate(
  start_date: string,
  end_date: string,
  isBusinessUser: boolean
) {
  const initDate = moment(start_date);
  const finalDate = moment(end_date);
  return isBusinessUser
    ? `${initDate.format('M/D/YY')} - ${finalDate.format('M/D/YY')}`
    : `${initDate.format('MMM D')} - ${finalDate.format(
        'MMM D'
      )}, ${finalDate.format('YYYY')}`;
}

export default memo(CampaignCard);

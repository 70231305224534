import { memo, useMemo, useEffect, useState, Fragment, useRef } from 'react';
import { Button, Alert, Space } from 'antd';
/* Components */

import DashboardTemplate from '../../templates/DashboardTemplate';
import BasicContentLayout from '../../templates/BasicContentLayout';
import PayoutsCard from './components/PayoutsCard';
import { ChartConfig, PayoutsStats } from '../../../types/payouts';
/* Services */
import { getPaymentStats } from '../../../services/promoter';
/* Files */
import pathBlue from '../../../assets/images/path-doughnut-blue.svg';
import pathGreen from '../../../assets/images/path-doughnut-green.svg';
/* Styles */
import './styles.scss';
import {
  connectAttempt,
  connectRefreshAttempt,
} from '../../../services/stripe';
import { useUserContex } from '../../../context/User';
import SplashScreen from '../../organisms/SplashScreen';

const chartConfig: ChartConfig = {
  label: '',
  value: 0,
  valueClass: '',
  data: {
    labels: [],
    datasets: [
      {
        label: '',
        data: [100],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        cutout: 53,
      },
    ],
  },
  options: {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  },
  pattern: '',
};

const Payouts = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user } = useUserContex()!;
  // console.log(user);

  const stripeButtonRef = useRef<HTMLButtonElement>(
    document.createElement('button')
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countScanChartConfig, setCountScanChartConfig] = useState<ChartConfig>(
    JSON.parse(JSON.stringify(chartConfig))
  );
  const [totalEarnedChartConfig, setTotalEarnedChartConfig] =
    useState<ChartConfig>(JSON.parse(JSON.stringify(chartConfig)));

  const [countScanDailyChartConfig, setCountScanDailyChartConfig] =
    useState<ChartConfig>(JSON.parse(JSON.stringify(chartConfig)));
  const [totalEarnedDailyChartConfig, setTotalEarnedDailyChartConfig] =
    useState<ChartConfig>(JSON.parse(JSON.stringify(chartConfig)));

  const [countPastSevenChartConfig, setCountPastSevenChartConfig] =
    useState<ChartConfig>(JSON.parse(JSON.stringify(chartConfig)));
  const [ernedPastSevenChartConfig, setErnedPastSevenChartConfig] =
    useState<ChartConfig>(JSON.parse(JSON.stringify(chartConfig)));

  const [availableRemaining, setAvailableRemaining] = useState<number>(0);

  const handleStartStripeConnectConfig = (): void => {
    setIsLoading(true);
    connectAttempt().then((data) => {
      if (data.url) {
        window.location = data.url;
      }
    });
  };
  const handleRefreshStripeConnectConfig = (): void => {
    setIsLoading(true);
    connectRefreshAttempt().then((data) => {
      console.log(data);
      if (data.url) {
        window.location = data.url;
      }
    });
  };
  const parsedDigit = (value: number) => {
    return value ? value.toFixed(2) : value;
  };

  const parsedInt = (value: number) => {
    return value ? value.toFixed(0) : value;
  };
  const today = useMemo(
    () => () => {
      const now = new Date();
      return `${
        Number(now.getMonth()) + 1
      }-${now.getDate()}-${now.getFullYear()}`;
    },
    []
  );

  useEffect(() => {
    (async () => {
      setCountScanChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.label = 'Number of scans';
        newCharConfig.value = 0;
        newCharConfig.valueClass = 'blue';
        newCharConfig.pattern = pathBlue;
        newCharConfig.data.datasets[0].backgroundColor.push('#2F80ED');
        newCharConfig.data.datasets[0].borderColor.push('#2F80ED');
        return newCharConfig;
      });

      setTotalEarnedChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.label = 'Estimated Earned';
        newCharConfig.value = '$0';
        newCharConfig.valueClass = 'green';
        newCharConfig.pattern = pathGreen;
        newCharConfig.data.datasets[0].backgroundColor.push('#6FCF97');
        newCharConfig.data.datasets[0].borderColor.push('#6FCF97');
        return newCharConfig;
      });

      setCountScanDailyChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.label = 'Number of scans';
        newCharConfig.value = 0;
        newCharConfig.valueClass = 'blue';
        newCharConfig.pattern = pathBlue;
        newCharConfig.data.datasets[0].backgroundColor.push('#2F80ED');
        newCharConfig.data.datasets[0].borderColor.push('#2F80ED');
        return newCharConfig;
      });

      setTotalEarnedDailyChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.label = 'Estimated Earned';
        newCharConfig.value = '$0';
        newCharConfig.valueClass = 'green';
        newCharConfig.pattern = pathGreen;
        newCharConfig.data.datasets[0].backgroundColor.push('#6FCF97');
        newCharConfig.data.datasets[0].borderColor.push('#6FCF97');
        return newCharConfig;
      });

      setCountPastSevenChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.label = 'Number of scans';
        newCharConfig.value = 0;
        newCharConfig.valueClass = 'blue';
        newCharConfig.pattern = pathBlue;
        newCharConfig.data.datasets[0].backgroundColor.push('#2F80ED');
        newCharConfig.data.datasets[0].borderColor.push('#2F80ED');
        return newCharConfig;
      });

      setErnedPastSevenChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.label = 'Estimated Earned';
        newCharConfig.value = '$0';
        newCharConfig.valueClass = 'green';
        newCharConfig.pattern = pathGreen;
        newCharConfig.data.datasets[0].backgroundColor.push('#6FCF97');
        newCharConfig.data.datasets[0].borderColor.push('#6FCF97');
        return newCharConfig;
      });

      const stats: PayoutsStats = await getPaymentStats();

      setCountScanChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.value = parsedInt(stats.countScan);
        return newCharConfig;
      });

      setTotalEarnedChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.value = '$' + parsedDigit(stats.totalEarned);
        return newCharConfig;
      });

      setCountScanDailyChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.value = parsedDigit(stats.countScanDaily);
        return newCharConfig;
      });

      setTotalEarnedDailyChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.value = '$' + parsedDigit(stats.totalEarnedDaily);
        return newCharConfig;
      });

      setCountPastSevenChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.value = parsedDigit(stats.countPastSevenDays);
        return newCharConfig;
      });

      setErnedPastSevenChartConfig((prevState) => {
        const newCharConfig = { ...prevState };
        newCharConfig.value = '$' + parsedDigit(stats.ernedPastSevenDays);
        return newCharConfig;
      });

      setAvailableRemaining(stats.availableRemaining);
    })();
  }, []);
  return (
    <div className='Payouts'>
      <DashboardTemplate
        mainContent={
          <BasicContentLayout>
            {isLoading ? (
              <SplashScreen message='Redirecting to stripe connect...' />
            ) : (
              <Fragment>
                {user.data?.userExtras['stripe-account'] &&
                  !user.data?.userExtras['stripe-account'].charges_enabled && (
                    <Alert
                      message='Warning'
                      description='Payouts will not be sent until your connection to Stripe is complete.'
                      type='warning'
                      showIcon
                      action={
                        <Space>
                          <Button
                            size='small'
                            onClick={() =>
                              stripeButtonRef.current.scrollIntoView()
                            }
                          >
                            Finalize
                          </Button>
                        </Space>
                      }
                    />
                  )}

                <h2>Wallet</h2>
                <p className='gray'>
                  Payouts available for transfer every Friday (May take up to 24
                  hours to settle).
                </p>

                <div className='Payouts-cards-container'>
                  <PayoutsCard
                    cardLabel={`Today ${today()}`}
                    chartConfig1={countScanDailyChartConfig}
                    chartConfig2={totalEarnedDailyChartConfig}
                  />
                  <PayoutsCard
                    cardLabel='Past 7 days'
                    chartConfig1={countPastSevenChartConfig}
                    chartConfig2={ernedPastSevenChartConfig}
                  />
                </div>
                <div className='Payouts-cards-container'>
                  <PayoutsCard
                    cardLabel='Accumulated total'
                    chartConfig1={countScanChartConfig}
                    chartConfig2={totalEarnedChartConfig}
                  />
                  <div className='Payouts-bottom text-right'>
                    <p>
                      Total available:{' '}
                      <span className='Payouts-price'>
                        ${availableRemaining.toFixed(2)}
                      </span>
                    </p>
                    {user?.data && (
                      <Button
                        type='primary'
                        className='Payouts-btn-transfer'
                        ref={stripeButtonRef}
                        onClick={
                          user.data?.userExtras['stripe-account']
                            ? handleRefreshStripeConnectConfig
                            : handleStartStripeConnectConfig
                        }
                        disabled={
                          user.data?.userExtras['stripe-account'] &&
                          user.data?.userExtras['stripe-account']
                            .charges_enabled
                        }
                      >
                        {user.data?.userExtras['stripe-account']
                          ? user.data?.userExtras['stripe-account']
                              .charges_enabled
                            ? 'Connected to Stripe'
                            : 'Continue in Stripe'
                          : 'Connect to Stripe'}
                      </Button>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </BasicContentLayout>
        }
      />
    </div>
  );
};

export default memo(Payouts);

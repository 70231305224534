import { Link } from 'react-router-dom';
/* Files */
import siteLogo from '@/assets/images/scan-panda-landing-banner.png';
/* Styles */
import './styles.scss';

const HorizontalLogo = (): JSX.Element => {
  return (
    <Link to='/landing'>
      <div className='Horizontal-logo-container'>
        <img
          className='Horizontal-logo-img'
          src={siteLogo}
          style={{ maxHeight: '400px' }}
        />
        {/* <img className='Horizontal-logo-img' src={siteLogo} style={{}} /> */}
        <div></div>
      </div>
    </Link>
  );
};

export default HorizontalLogo;

import { useEffect, useRef, useState } from 'react';
import { useCampaignProvider } from '../context/Campaigns';
import { getCities } from '../services/campaign';
import { City } from '../types/city';

export const useSearchCampaigns = () => {
  const [options, setOptions] = useState<
    Array<{
      value: string;
      label: string;
    }>
  >([]);
  const [cityQuery, setCityQuery] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const isInitialMount = useRef(false);

  const { searchCampaigns } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useCampaignProvider()!;

  useEffect(() => {
    if (!isInitialMount.current) {
      isInitialMount.current = true;
      return;
    }
    if (searchCampaigns) {
      searchCampaigns(city, searchText);
    }
  }, [city, searchText]);

  useEffect(() => {
    (async () => {
      const cities: Array<City> = await getCities(cityQuery);
      if (cities) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const autocompleteDataset = cities.map((city: any) => {
          return {
            value: city.city + ', ' + city.admin_name,
            label: city.city + ', ' + city.admin_name,
          };
        });

        setOptions([...autocompleteDataset, { value: '', label: 'Any city' }]);
      }
    })();
  }, [cityQuery]);

  return {
    city,
    searchText,
    setSearchText,
    setCityQuery,
    setCity,
    options,
    searchCampaigns,
  };
};

import { memo, useCallback, useState } from 'react';
import { Form, Input, InputNumber, Button, Upload, Row, Col } from 'antd';
import { UserData } from '../../types/user';
import logo from '@/assets/images/user-circle.svg';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/lib/upload/interface';
import { resizeImage } from '../../utils';

const SignUpBusinessForm = ({
  type = 'personal',
  getFormData,
}: {
  type?: string;
  getFormData: (values: UserData) => void;
}): JSX.Element => {
  const isBusiness = type == 'business';
  const [form] = Form.useForm();
  const [urlImage, setUrlImage] = useState(logo);
  const [dataImage, setDataImage] = useState<Blob>();

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.fileList[0]) {
      getBase64(info.fileList[0].originFileObj as RcFile, (url) => {
        setUrlImage(url);
      });
    } else {
      setUrlImage(logo);
    }
  };

  const beforeUpload = async (file: RcFile) => {
    const imageResize = await resizeImage({
      file: file,
      maxSize: 500,
    });
    setDataImage(imageResize);
    return false;
  };

  const getFile = (e: { fileList: any }) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && dataImage;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = useCallback(
    (values: any): void => {
      getFormData({ ...values });
      form.resetFields();
      setUrlImage(logo);
    },
    [getFormData]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = useCallback((errorInfo: any): void => {
    console.log('Failed:', errorInfo);
  }, []);

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={{
        website: 'https://YourWebsiteHere.com',
      }}
    >
      <Form.Item
        name='firstName'
        rules={[{ required: true, message: 'Please input your first name!' }]}
      >
        <Input placeholder='First Name' />
      </Form.Item>

      <Form.Item
        name='lastName'
        rules={[{ required: true, message: 'Please input your last name!' }]}
      >
        <Input placeholder='Last name' />
      </Form.Item>

      <Form.Item
        name='email'
        rules={[
          {
            type: 'email',
            required: true,
            message: 'Please enter a valid email!',
          },
        ]}
      >
        <Input placeholder='Email' />
      </Form.Item>

      {isBusiness && (
        <>
          <Form.Item
            name='businessName'
            rules={[
              { required: true, message: 'Please input your business name!' },
            ]}
          >
            <Input placeholder='Business name' />
          </Form.Item>
          <Row align='top'>
            <Col span={4}>
              <Form.Item>
                <div
                  style={{
                    backgroundImage: `url(${urlImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '53px',
                    width: '53px',
                    borderRadius: '50%',
                    marginTop: '12px',
                  }}
                ></div>
              </Form.Item>
            </Col>

            <Col span={20}>
              <Form.Item
                name='dataImage'
                rules={[
                  {
                    required: true,
                    message: 'Please add your business profile image',
                  },
                ]}
                getValueFromEvent={getFile}
                valuePropName='dataImage'
              >
                <Upload.Dragger
                  name='businessImage'
                  multiple={false}
                  onChange={handleChange}
                  showUploadList={false}
                  accept={'image/jpg, image/png'}
                  beforeUpload={beforeUpload}
                  maxCount={1}
                >
                  <div className='ant-upload-text'>
                    Add Business Profile Image
                  </div>
                  <div className='ant-upload-hint'>JPG or PNG</div>
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Form.Item
        name='phoneNumber'
        rules={[
          {
            type: 'number',
            required: true,
            message: 'Please input your phone number!',
          },
        ]}
      >
        <InputNumber placeholder='Phone number' />
      </Form.Item>

      {isBusiness && (
        <Form.Item
          name='website'
          rules={[
            {
              type: 'url',
              required: true,
              message: 'Please input your a valid website!',
            },
          ]}
        >
          <Input placeholder='Website' />
        </Form.Item>
      )}

      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            min: 6,
            message: 'Password must be at least 6 characters',
          },
        ]}
      >
        <Input.Password placeholder='Password' />
      </Form.Item>

      <Form.Item
        name='repeatPassword'
        rules={[
          { required: true, min: 6, message: 'Please repeat your password!' },
        ]}
      >
        <Input.Password placeholder='Repeat Password' />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Sign Up for free
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(SignUpBusinessForm);

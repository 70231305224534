import apiCall from './utils';
import { API_URL } from '../constants';
import { UserData } from '../types/user';

export interface RequestUser {
  user: UserData;
  userExtras?: any;
}

export const getUserData = async (uid: string): Promise<RequestUser> => {
  const response = await apiCall({ url: `${API_URL}/users/${uid}` });
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserFullData = async (): Promise<any> => {
  const response = await apiCall({ url: `${API_URL}/users/profile` });
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createUser = async (values: any): Promise<any> => {
  const formData = new FormData();
  Object.keys(values).map((key) => {
    formData.append(key, values[key]);
  });

  const response = await apiCall({
    url: `${API_URL}/users/`,
    method: 'post',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data; boundary=XXX',
    },
  });
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUser = async (values: UserData): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/users/update-profile`,
    method: 'put',
    body: values,
  });
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePhoto = async (values: UserData): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/users/update-photo`,
    method: 'put',
    body: values,
  });
  return response.data;
};

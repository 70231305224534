import { memo, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
/* Components */
import ShowCampaign from '../components/pages/ShowCampaign';
import SignUp from '../components/pages/SignUp';
import SignIn from '../components/pages/SignIn';
import NewWordOfMmouthCampaign from '../components/pages/NewWordOfMmouthCampaign';
import Profile from '../components/pages/Profile';
import Campaigns from '../components/pages/Campaigns';
import Payouts from '../components/pages/Payouts';
import Page404 from '../components/pages/Page404';
/* Hooks */
import { useLocalStorage } from '../hooks';
/* Context */
import { useUserContex } from '../context/User';
/* Constants */
import { LOCAL_STORAGE_KEY } from '../constants';
import Wallet from '../components/pages/BusinessWallet';
import NewPaymentMethod from '../components/pages/NewPaymentMethod';
import Landing from '../components/pages/Landing';
import ShareCampaign from '../components/pages/ShareCampaign';
const HomeRedirect = memo(() => {
  window.location.href = '/landing';
  return null;
});
export default function Routes(): JSX.Element {
  const [storedValue] = useLocalStorage(LOCAL_STORAGE_KEY, {});
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { getUserData, user } = useUserContex()!;
  useEffect(() => {
    user.authenticated;
  });
  useEffect(() => {
    if (storedValue && storedValue.token) {
      getUserData(storedValue.uid);
    }
  }, [storedValue, getUserData]);

  return (
    <Router>
      <Switch>
        <Route path='/' component={HomeRedirect} exact />
        <Route path='/landing' component={Landing} exact />
        <Route path='/login' component={SignIn} exact />
        <Route path='/sign-up' component={() => <SignUp />} exact />
        <Route
          path='/sign-up/business'
          component={() => <SignUp isbusiness={true} />}
          exact
        />
        <Route path='/show-campaign' component={ShowCampaign} exact />
        <Route path='/share-campaign' component={ShareCampaign} exact />
        <PrivateRoute
          path='/campaigns/new'
          component={NewWordOfMmouthCampaign}
          exact
        />
        <PrivateRoute path='/campaigns' component={Campaigns} exact />
        <PrivateRoute path='/wallet' component={Payouts} exact />
        <PrivateRoute path='/profile' component={Profile} exact />
        <PrivateRoute path='/business-wallet' component={Wallet} exact />
        <PrivateRoute
          path='/payment/new-method'
          component={NewPaymentMethod}
          exact
        />

        <Route path='**' component={Page404} />
      </Switch>
    </Router>
  );
}

function PrivateRoute({ children, ...routeProps }: RouteProps) {
  const [storedValue] = useLocalStorage(LOCAL_STORAGE_KEY, {});
  const { user } = useUserContex();
  return (
    <Route
      {...routeProps}
      render={({ location }) =>
        (storedValue && storedValue.token) || user.authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

import { memo } from 'react';
/* Compoenents */
import HorizontalLogo from '../../atoms/HorizontalLogo';
/* Styles */
import './styles.scss';

const SimpleHeader = ({}: { logoType?: string }): JSX.Element => {
  return (
    <div className='container'>
      <HorizontalLogo />
    </div>
  );
};

export default memo(SimpleHeader);

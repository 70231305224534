import { memo, useCallback } from 'react';
import { Form, Input, Button } from 'antd';

const RecoverPassForm = ({
  className,
  getFormData,
  isLoading,
}: {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFormData: (values: any) => void;
  isLoading: boolean;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = useCallback((values: any): void => {
    getFormData(values);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = useCallback((errorInfo: any): void => {
    console.log('Failed:', errorInfo);
  }, []);

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className={className}
    >
      <Form.Item
        name='email'
        rules={[
          {
            type: 'email',
            required: true,
            message: 'Please enter a valid email!',
          },
        ]}
      >
        <Input placeholder='Email' />
      </Form.Item>
      {isLoading && <div>sending...</div>}
      <Form.Item>
        <Button type='primary' htmlType='submit' disabled={isLoading}>
          Send
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(RecoverPassForm);

import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Skeleton } from 'antd';
import Icon from './../../../../atoms/Icon';

import './styles.scss';
import { useUserContex } from '../../../../../context/User';

const SideBarMenu = ({
  isLoading,
  userType,
}: {
  isLoading: boolean;
  userType: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { logout } = useUserContex()!;

  return (
    <div className='SideBarMenu'>
      {isLoading ? (
        <div className='skeleton-container'>
          <Skeleton active paragraph={{ rows: 3 }} />
        </div>
      ) : (
        <Menu
          style={{ width: '100%', background: '#171717', color: '#ffffff' }}
          defaultSelectedKeys={['1']}
          mode='inline'
        >
          <Menu.Item key='1'>
            <Link to='/campaigns'>
              <Icon name='volume' fill='dark' />
              <span className='ml-3'>Campaigns</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='2' className='hidden'>
            <Icon name='bell' fill='dark' />
            <span className='ml-3'>Notitfications</span>
          </Menu.Item>
          {userType === 'PROMOTER' && (
            <Menu.Item key='3'>
              <Link to='/wallet'>
                <Icon name='wallet' fill='dark' />
                <span className='ml-3'>Wallet</span>
              </Link>
            </Menu.Item>
          )}
          {/*{userType === 'BRANDS' && (*/}
          {/*  <Menu.Item key='3'>*/}
          {/*    <Link to='/business-wallet'>*/}
          {/*      <Icon name='wallet' fill='dark' />*/}
          {/*      <span className='ml-3'>Payment</span>*/}
          {/*    </Link>*/}
          {/*  </Menu.Item>*/}
          {/*)}*/}

          <Menu.Item key='4'>
            <Link to='/profile'>
              <Icon name='settings' fill='dark' />
              <span className='ml-3'>Profile</span>
            </Link>
          </Menu.Item>

          <Menu.Item key='5' onClick={logout}>
            <Icon name='logout' fill='dark' />
            <span className='ml-3'>Logout</span>
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};

export default memo(SideBarMenu);

import { useState, useEffect } from 'react';
import { Input as AndtInput } from 'antd';
import './style.scss';

const LabeledInput = ({
  className = '',
  inputValue,
  id,
  label,
  onValueChange,
  placeholder,
}: {
  className?: string;
  inputValue?: string;
  id?: string;
  label?: string;
  onValueChange?: (arg1: string) => boolean;
  placeholder?: string;
}): JSX.Element => {
  const [labeledInputValue, setlabeledInputValue] = useState(inputValue);

  const handleOnInputChange = (newValue: string): void => {
    if (onValueChange) {
      if (onValueChange(newValue)) {
        setlabeledInputValue(newValue);
      }
    }
  };

  useEffect(() => {
    setlabeledInputValue(inputValue);
  }, [inputValue]);

  return (
    <label className={`labeled-input ${className}`}>
      <small>{label}</small>
      <AndtInput
        id={id}
        placeholder={placeholder}
        value={labeledInputValue}
        onChange={(event) => handleOnInputChange(event.target.value)}
      ></AndtInput>
    </label>
  );
};

export default LabeledInput;

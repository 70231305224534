import React, { useRef, useState, useEffect } from 'react';
import { IconTypes, IconSizes, IconThemes } from '../../../types/icon';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconTypes;
  size?: IconSizes | number;
  fill?: IconThemes | string;
  className?: string;
}

const Icon: React.FC<IconProps> = ({
  name,
  size = 'small',
  fill = 'light',
  className,
}): JSX.Element | null => {
  const ImportedIconRef = useRef<React.FC<
    React.SVGProps<SVGSVGElement>
  > | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let mount = true;
    const importIcon = async () => {
      try {
        const { default: namedImport } = await import(
          `!!@svgr/webpack?-svgo,+titleProp,+ref!../../../assets/images/${name}.svg`
        );
        ImportedIconRef.current = namedImport;
      } catch (err) {
        throw err;
      } finally {
        mount && setLoading(false);
      }
    };
    importIcon();
    return () => {
      mount = false;
    };
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    let width,
      height = 14;
    if (size == 'medium') {
      width = height = 31;
    } else if (typeof size === 'number') {
      width = height = size;
    }

    let stroke = '';
    const filledIcons = ['volume', 'edit', 'share', 'trash', 'more-vertical'];

    if (fill == 'light') {
      fill = 'none';
      stroke = 'var(--color-black)';
      if (filledIcons.find((elem) => elem === name)) {
        fill = 'var(--color-black)';
        stroke = 'none';
      }
    } else if (fill == 'dark') {
      fill = 'none';
      stroke = 'var(--color-white)';
      if (filledIcons.find((elem) => elem === name)) {
        fill = 'var(--color-white)';
        stroke = 'none';
      }
    }

    const iProps = {
      width,
      height,
      stroke,
      fill,
      className,
    };

    if (className) {
      iProps.className = className;
    }

    return <ImportedIcon {...iProps} />;
  }

  return null;
};

export default Icon;

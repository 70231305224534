import { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
/* Components */
import LandingTemplate from '../../templates/LandingTemplate';
import Header from '../../organisms/Header';
/* Services */
import { readCampain } from '../../../services/campaign';
/* Files */
import HomeImg from '../../../assets/images/home-scanpanda.png';
/* Styles */
import './styles.scss';
import { DeviceUUID } from 'device-uuid';

const ShowCampaign = (): JSX.Element => {
  const [messageType, setMessageType] = useState<string>('loading');
  const urlQuery = new URLSearchParams(useLocation().search);

  useEffect(() => {
    (async () => {
      if (!localStorage.getItem('DeviceUUID')) {
        const uuid = new DeviceUUID().get();
        localStorage.setItem('DeviceUUID', uuid);
      }
      console.log('data slug', urlQuery.get('data'));
      const decodedData = atob(urlQuery.get('data') || '');
      const { data0, data1 } = JSON.parse(decodedData);
      if (data0 && data1) {
        const response = await readCampain(
          data0 /* campaignId */,
          data1 /* businessId */,
          localStorage.getItem('DeviceUUID') as string
        );
        if (response) {
          console.log('resdata', response);
          const resData = response.data;
          if (!response.error) {
            if (resData.redirectUrl) {
              if (
                !(
                  resData.redirectUrl.includes('http') ||
                  resData.redirectUrl.includes('https')
                )
              ) {
                resData.redirectUrl = 'https://' + resData.redirectUrl;
              }
              window.location.replace(resData.redirectUrl);
            }
          } else if (response.error.response.status == 400) {
            setMessageType('expired');
          } else {
            setMessageType('invalid');
          }
        } else {
          setMessageType('invalid');
        }
      } else {
        setMessageType('invalid');
      }
    })();
  }, []);

  return (
    <div className='HomeBusiness'>
      <LandingTemplate
        bgMode='dark'
        header={<Header logoType='black' />}
        mainContent={
          <div className='HomeBusiness-content'>
            <Row
              justify='center'
              align='middle'
              className='HomeBusiness-main-row'
            >
              <Col xs={24} sm={24} md={12}>
                <h1 className='white'>
                  {messageType === 'loading' && <>Loading campaign...</>}
                  {messageType === 'expired' && (
                    <>
                      <span className='accent'>Oops!</span>This campaign has
                      ended
                    </>
                  )}
                  {messageType === 'invalid' && (
                    <>
                      <span className='accent'>Oops!</span>This campaign is not
                      valid
                    </>
                  )}
                </h1>
                <Button className='outline'>
                  <Link to='/'>Home</Link>
                </Button>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <img className='w-100' src={HomeImg} />
              </Col>
            </Row>
          </div>
        }
      />
    </div>
  );
};

export default memo(ShowCampaign);

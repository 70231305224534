import { useState, memo, useCallback, useEffect } from 'react';
import { Form, Input, InputNumber, Button, Row, Col } from 'antd';

import { getUserFullData, updateUser } from './../../../services/user';
import { openNotification } from '../../../utils';

/* Hooks */
import { useLocalStorage } from '../../../hooks';
/* Constants */
import { LOCAL_STORAGE_KEY } from '../../../constants';

/* Context */
import { useUserContex } from '../../../context/User';

import './styles.scss';

interface UserFullData {
  firstName?: string;
  lastName?: string;
  businessName?: string;
  email?: string;
  phoneNumber?: number;
  website?: string;
  role?: string;
}

const ProfileForm = (): JSX.Element => {
  const [form] = Form.useForm();
  const [localUser, setLocalUSer] = useState<UserFullData>({});
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [storedValue] = useLocalStorage(LOCAL_STORAGE_KEY, {});
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { getUserData } = useUserContex()!;

  useEffect(() => {
    (async () => {
      const userFullData = await getUserFullData();
      setLocalUSer(() => {
        return {
          firstName: userFullData.firstName,
          lastName: userFullData.lastName,
          businessName: userFullData.businessName,
          email: userFullData.email,
          phoneNumber: userFullData.phoneNumber,
          website: userFullData.website,
          role: userFullData.role,
        };
      });
    })();
  }, []);

  useEffect(() => {
    if (localUser?.role === 'business') {
      form.setFieldsValue({
        firstName: localUser.firstName,
        lastName: localUser.lastName,
        businessName: localUser.businessName,
        email: localUser.email,
        phoneNumber: localUser.phoneNumber,
        website: localUser.website,
      });
    } else {
      form.setFieldsValue({
        firstName: localUser.firstName,
        lastName: localUser.lastName,
        email: localUser.email,
        phoneNumber: localUser.phoneNumber,
      });
    }
  }, [localUser]);

  const onFinish = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: any): void => {
      setIsProcessing(true);
      updateUser(values)
        .then(() => {
          //Launch a success notification
          openNotification(
            'success',
            'Update user',
            'Your profile data was updated successfully'
          );
          setIsProcessing(false);
          //Ask for newer user data to update context
          if (storedValue && storedValue.token) {
            getUserData(storedValue.uid);
          }
          //empty password fields
          form.setFieldsValue({
            password: '',
            repeatPassword: '',
          });
        })
        .catch((error) => {
          setIsProcessing(false);
          console.error(error);
          openNotification(
            'error',
            'Update user',
            'Sorry! an error has occurred. Your data could not be updated'
          );
        });
    },
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = useCallback((errorInfo: any): void => {
    console.log('Failed:', errorInfo);
    setIsProcessing(false);
  }, []);

  return (
    <Form
      form={form}
      className='ProfileForm'
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col sm={24} md={12}>
          <Form.Item
            name='firstName'
            rules={[
              { required: true, message: 'Please input your first name!' },
            ]}
          >
            <Input placeholder='First Name' />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            name='lastName'
            rules={[
              { required: true, message: 'Please input your last name!' },
            ]}
          >
            <Input placeholder='Last name' />
          </Form.Item>
        </Col>
      </Row>
      {localUser?.role === 'business' && (
        <Row>
          <Col span={24}>
            <Form.Item
              name='businessName'
              rules={[
                { required: true, message: 'Please input your business name!' },
              ]}
            >
              <Input placeholder='Business name' />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={24} md={12}>
          <Form.Item
            name='email'
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please enter a valid email!',
              },
            ]}
          >
            <Input placeholder='Email' />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            name='phoneNumber'
            rules={[
              {
                type: 'number',
                required: false,
                message: 'Please input your phone number!',
              },
            ]}
          >
            <InputNumber placeholder='Phone number' />
          </Form.Item>
        </Col>
      </Row>
      {localUser?.role === 'business' && (
        <Row>
          <Col span={24}>
            <Form.Item
              name='website'
              rules={[
                {
                  type: 'url',
                  required: false,
                  message: 'Please input your a valid website!',
                },
              ]}
            >
              <Input placeholder='Website' />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <div className='ant-form-item'>
            <h3>Change password</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={24} md={12}>
          <Form.Item
            name='password'
            rules={[
              {
                required: false,
                min: 6,
                message: 'Password must be at least 6 characters',
              },
            ]}
          >
            <Input.Password placeholder='Password' />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            name='repeatPassword'
            dependencies={['password']}
            rules={[
              {
                required: false,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two password should match!')
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder='Repeat Password' />
          </Form.Item>
        </Col>
      </Row>
      {isProcessing && <div className='text-center'>Processing...</div>}
      <Form.Item className='text-center m-0'>
        <Button type='primary' htmlType='submit'>
          Update Profile
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(ProfileForm);

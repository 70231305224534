import firebase from 'firebase/compat';
import 'firebase/auth';
import 'firebase/storage';
/* Files */
import serviceAccount from './service-account-prod.json';

firebase.initializeApp(serviceAccount);

export const auth = firebase.auth();
export const storage = firebase.storage();

// if (process.env.NODE_ENV === 'development') {
//   auth.useEmulator('http://localhost:9099');
// }

import apiCall from './utils';
import { API_URL } from '../constants';
import { Campaign } from '../types/campaigns';
import { PayoutsStats } from '../types/payouts';

export const findCampaign = async (
  status: string,
  sort = 'desc'
): Promise<Array<Campaign>> => {
  const response = await apiCall({
    url: `${API_URL}/promoters/campaigns/${status}?sort=${sort}`,
  });
  return response.data;
};

export const findCampaignByRadius = async (
  lat: number,
  lan: number
): Promise<Array<Campaign>> => {
  const response = await apiCall({
    url: `${API_URL}/campaigns-radius?lat=${lat}&lng=${lan}`,
  });
  return response.data;
};

export const getPaymentStats = async (): Promise<PayoutsStats> => {
  const response = await apiCall({
    url: `${API_URL}/promoters/payouts/`,
  });
  return response.data;
};

export const joinCampaign = async (
  campaignId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/promoters/${campaignId}/join`,
    method: 'post',
    body: {},
  });
  return response.data;
};

export const unjoinCampaign = async (
  campaignId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/promoters/${campaignId}/remove`,
    method: 'delete',
    body: {},
  });
  return response.data;
};

import { memo } from 'react';
import { Link } from 'react-router-dom';
/* Components */
import DashboardTemplate from '../../templates/DashboardTemplate';
import ProfileForm from '../../organisms/ProfileForm';
import Icon from '../../atoms/Icon';

/* Styles */
import './styles.scss';

const NewWordOfMmouthCampaign = (): JSX.Element => {
  return (
    <div className='Profile'>
      <DashboardTemplate
        mainContent={
          <div className='card-wrapper'>
            <div className='wow-card'>
              <span className='close-action'>
                <Link to='/campaigns'>
                  <Icon name='close' />
                </Link>
              </span>
              <h2 className='wow-card-title'>User profile data</h2>

              <ProfileForm />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default memo(NewWordOfMmouthCampaign);

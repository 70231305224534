import siteLogo from '@/assets/images/scan-panda-logo.png';
import spinner from '@/assets/images/spinner.svg';

const SplashScreen = ({ message = 'Loading Data...' }) => {
  return (
    <div className='loading-block'>
      <img
        className='loading-panda'
        src={siteLogo}
        style={{ width: '80px', marginBottom: '10px' }}
      />
      <img className='loading-spinner' src={spinner} />
      <h2>{message}</h2>
    </div>
  );
};
export default SplashScreen;

import { memo, useState, useCallback, useEffect } from 'react';
import { Modal, Button, Row, Col, notification, Input } from 'antd';
import * as htmlToImage from 'html-to-image';
import Icon from '../../../atoms/Icon';
import siteLogo from '@/assets/images/scan-panda-logo.png';

/*Types*/
import { ModalCampaignDataSet } from '../../../../types/campaigns';

import QRCodeImage from '@/assets/images/qr-code.jpg';
import './styles.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const QRCode = require('qrcode');

const ScanQRModal = ({
  campaignData,
  modalVisibility = false,
  onModalDismiss,
}: {
  campaignData: ModalCampaignDataSet;
  modalVisibility?: boolean;
  onModalDismiss?: () => void;
}): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(modalVisibility);
  const [qrImg, setQrImg] = useState<string>(QRCodeImage);

  const handleOk = useCallback(() => {
    setIsModalVisible(false);

    if (typeof onModalDismiss === 'function') {
      onModalDismiss();
    }
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    if (typeof onModalDismiss === 'function') {
      onModalDismiss();
    }
  }, []);

  useEffect(() => {
    setIsModalVisible(modalVisibility);
  }, [modalVisibility]);

  useEffect(() => {
    crearQR(10, (qrImgB64: string) => {
      setQrImg(qrImgB64);
    });
  }, [isModalVisible]);

  function crearQR(scale: number, setter: (param: string) => void) {
    console.log('QR TEXT:', campaignData.QRtext);
    QRCode.toDataURL(
      campaignData.QRtext,
      {
        errorCorrectionLevel: 'L',
        margin: 1,
        scale,
        type: 'image/png',
      },
      function (_err: Error, url: string) {
        setter(url);
      }
    );
  }

  const downloadQR = async () => {
    const node = document.getElementById('scan-area');
    if (!node) return false;
    await htmlToImage.toPng(node);
    await htmlToImage.toPng(node);
    await htmlToImage.toPng(node);

    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        console.log('qr-complete', dataUrl);

        const link = document.createElement('a');
        link.href = dataUrl;
        link.setAttribute('download', `qr-code.png`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      })
      .catch(function (error) {
        console.error('Error on QR download', error);
      });
  };

  const copyToClipboard = (text: string) => {
    if (typeof ClipboardItem && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          notification['success']({
            message: 'Link copied to clipboard',
          });
          console.log('link copied.');
        })
        .catch((e) => {
          console.error(e, e.message);
        });
    } else {
      notification['error']({
        message: 'This browser does not support copy to clipboard',
      });
    }
  };

  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}
      className='ScanQRModal'
    >
      <div className='ScanQRModal-content'>
        <div className='campaign-info'>
          <div className='campaign-logo'>
            <img
              className='campaign-logo-img'
              src={
                campaignData.campaignPhoto
                  ? campaignData.campaignPhoto
                  : siteLogo
              }
            />
          </div>
          <div className='campaign-data'>
            <h2>{campaignData.campaignOwner}</h2>
            <p>
              Pay Per Click
              <span>${campaignData.campaignPayPerScan}</span>
            </p>
          </div>
        </div>
        <div id='scan-area' className='qr-style'>
          <p style={{ textTransform: 'capitalize' }}>
            {campaignData.campaignName}
          </p>
          <div id='scan-panda-wrapper' className='scan-panda-wrapper'>
            {/* <img
              id='scan-panda'
              className='scan-panda'
              src={ScanPanda}
              alt='Irep Brands'
            /> */}
            <img
              alt='QR Code'
              className='scan-panda-qr'
              id='QRCodeImage'
              src={qrImg}
            />
            {/* <img
              alt='QR small logo'
              className='small-qr-logo'
              id='QRSmallLogo'
              src={ScanPanda}
            /> */}
          </div>
        </div>
        <div className='text-center  my-5'>
          <Row className='button-area' align='middle'>
            <Col sm={24} md={9} className='my-2'>
              <Button
                type='primary'
                htmlType='button'
                className='button'
                onClick={() => {
                  setTimeout(() => {
                    downloadQR();
                  }, 500);
                }}
              >
                <Icon name='download' fill='dark' />
                <span className='pl-2'>
                  <a className='white download-link'> Download QR </a>
                </span>
              </Button>
            </Col>

            <Col sm={24} md={15} className='my-2'>
              <div className='share-area'>
                <Button
                  type='primary'
                  className='button-share'
                  size='small'
                  onClick={() => copyToClipboard(campaignData.QRtext)}
                >
                  Copy Url
                </Button>
                <Input
                  defaultValue={campaignData.QRtext}
                  value={campaignData.QRtext}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ScanQRModal);

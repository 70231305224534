import { Fragment, memo, useState, useCallback, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Skeleton,
} from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
/* Components */
import BasicContentLayout from '../../templates/BasicContentLayout';
import DashboardTemplate from '../../templates/DashboardTemplate';
import CampaignCard from '../../organisms/CampaignCard';
import ScanQRModal from '../../organisms/Modals/ScanQRModal';
import Icon from '../../atoms/Icon';
import moment from 'moment';
/* Types */
import { Campaign, ModalCampaignDataSet } from '../../../types/campaigns';
/* Context */
import { useUserContex } from '../../../context/User';
import { useCampaignProvider } from '../../../context/Campaigns';
/* Constants */
import { APP_URL } from './../../../constants';
/* Files */
import ImgNoCampaigns from '../../../assets/images/no-campaigns.svg';
/* Styles */
import './styles.scss';
import { useSearchCampaigns } from '../../../hooks/useSearchCampaigns';

const Campaigns = (): JSX.Element => {
  const [scanQRModalVisibility, setScanQRModalVisibility] =
    useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Array<Campaign>>([]);
  const [campaignType, setCampaignType] = useState<string>('my-campaigns');
  const [modalCampaignData, setModalCampaignData] =
    useState<ModalCampaignDataSet>({
      campaignPhoto: '',
      campaignName: '',
      campaignOwner: '',
      campaignPayPerScan: 0,
      QRtext: '',
    });
  const {
    isLoading: isCampaignsLoading,
    campaigns: providerCampaigns,
    loadCampaigns,
  } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useCampaignProvider()!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user, isLoading } = useUserContex()!;
  const isBusinessUser = user.data?.role == 'business';
  const campainsLength = campaigns.length;
  const {
    city,
    searchText,
    setSearchText,
    setCityQuery,
    setCity,
    options,
    searchCampaigns,
  } = useSearchCampaigns();
  const [sort, setSort] = useState<string>('recent');
  const [form] = Form.useForm();
  const { Search } = Input;
  const { Option } = Select;
  const location = useLocation();
  let title = '';

  useEffect(() => {
    if (user.data?.role) {
      let campaignsType =
        user.data.role === 'business' ? 'play' : 'my-campaigns';
      const state: any = location?.state;
      if (state?.tab) {
        if (user.data?.role === 'promoter') {
          if (state.tab === 'find-campaign' || state.tab === 'my-expired') {
            campaignsType = state.tab;
          }
        } else if (user.data?.role === 'business') {
          if (state.tab === 'pause' || state.tab === 'expired') {
            campaignsType = state.tab;
          }
        }
      }
      setCampaignType(campaignsType);
      updateCampains({
        campaignsType,
      });
    }
  }, [user.data?.role]);

  const onCampaignTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      setCampaignType(e.target.value);
      updateCampains({
        campaignsType: e.target.value,
      });
    },
    [campaigns]
  );

  useEffect(() => {
    handleSort(sort, providerCampaigns);
    setCampaigns(providerCampaigns);
  }, [providerCampaigns]);

  const updateCampains = ({
    campaignsType,
  }: {
    campaignsType: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => {
    if (loadCampaigns && searchCampaigns) {
      console.log('UPDATE', campaignsType);
      switch (campaignsType) {
        case 'play':
          loadCampaigns('business', campaignsType);
          break;

        case 'pause':
          loadCampaigns('business', campaignsType);
          break;

        case 'expired':
          loadCampaigns('business', campaignsType);
          break;

        case 'my-campaigns':
          loadCampaigns('promoter-my', 'active');
          break;

        case 'my-expired':
          loadCampaigns('promoter-my', 'expired');
          break;

        case 'find-campaign':
          searchCampaigns(city, searchText);
          break;

        default:
          break;
      }
    }
  };

  const handleSort = (value: string, campaigns: Array<Campaign>) => {
    setSort(value);
    if (value === 'budget-lowest') {
      campaigns.sort((a, b) => a.budget - b.budget);
      setCampaigns([...campaigns]);
    } else if (value === 'budget-highest') {
      campaigns.sort((a, b) => b.budget - a.budget);
      setCampaigns([...campaigns]);
    } else {
      campaigns.sort((a, b) => moment(b.start_date).diff(a.start_date));
      setCampaigns([...campaigns]);
    }
  };

  const onSearchSelect = (val: string) => {
    setCityQuery(val);
  };

  const onSearch = (val: string) => {
    setSearchText(val);
  };

  const onSelect = (val: string) => {
    setCity(val);
  };

  const handleCampaignJoin = useCallback(
    (campaignData: ModalCampaignDataSet): void => {
      setModalCampaignData(() => {
        return {
          ...campaignData,
        };
      });
      setScanQRModalVisibility(true);
    },
    [setScanQRModalVisibility]
  );

  const handleCampaignStart = useCallback((): void => {
    console.log('Campaign started or stopped');
  }, [campaigns, campaignType]);

  if (isBusinessUser || campaignType === 'my-campaigns') {
    title = 'My Campaigns';
  } else if (campaignType === 'find-campaign') {
    title = 'Find campaign';
  } else if (campaignType === 'my-expired') {
    title = 'Expired campaigns';
  }

  return (
    <div className='Campaigns'>
      <DashboardTemplate
        mainContent={
          <BasicContentLayout>
            <div
              className={
                'Campaigns-header' +
                (!isBusinessUser ? ' personal-content' : '')
              }
            >
              <h2 className='title-container'>{title}</h2>

              {!isLoading && (
                <Fragment>
                  <div className='campaign-mode-selector'>
                    {user.data?.role && !isBusinessUser && (
                      <Radio.Group
                        value={campaignType}
                        onChange={onCampaignTypeChange}
                      >
                        <Radio.Button value='my-campaigns' className='mr-3'>
                          My campaigns
                        </Radio.Button>
                        <Radio.Button value='my-expired' className='mr-3'>
                          Expired
                        </Radio.Button>
                        <Radio.Button value='find-campaign'>
                          Find campaign
                        </Radio.Button>
                      </Radio.Group>
                    )}

                    {user.data?.role && isBusinessUser && (
                      <Radio.Group
                        value={campaignType}
                        onChange={onCampaignTypeChange}
                      >
                        <Radio.Button value='play'>Active</Radio.Button>
                        <Radio.Button value='pause'>Paused</Radio.Button>
                        <Radio.Button value='expired'>Expired</Radio.Button>
                      </Radio.Group>
                    )}
                  </div>
                  <div className='create-button-container'>
                    {user.data?.role && isBusinessUser && (
                      <Link to='/campaigns/new'>
                        <Button type='primary'>Create new</Button>
                      </Link>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
            {campaignType === 'find-campaign' && (
              <Form
                className='Landing-form'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name='basic'
                onFinish={() => ''}
                onFinishFailed={() => ''}
                onValuesChange={() => ''}
                form={form}
                style={{ marginTop: '40px' }}
                initialValues={{
                  landingPage: 'https://sendcustomerhere.com',
                }}
              >
                <Row gutter={16}>
                  <Col xs={24} sm={7}>
                    <Form.Item name='title' style={{ marginBottom: '0px' }}>
                      <Search
                        placeholder='Search brands, keyword, etc'
                        onSearch={(value) => onSearch(value)}
                        size='large'
                        style={{ height: '40px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={5}>
                    <Select
                      defaultValue={sort}
                      onChange={(value) => handleSort(value, campaigns)}
                      style={{ width: '100%' }}
                      size={'large'}
                    >
                      <Option value='recent'>Most Recent</Option>
                      <Option value='budget-highest'>
                        Budget: Highest to Lowest
                      </Option>
                      <Option value='budget-lowest'>
                        Budget: Lowest to Highest
                      </Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={4}>
                    <Form.Item
                      style={{ marginBottom: '0px' }}
                      name='city'
                      rules={[
                        {
                          required: false,
                          message: 'Please input city or region!',
                        },
                        {
                          type: 'string',
                          message: 'Please, enter a valid city!',
                        },
                      ]}
                    >
                      <Select
                        options={options}
                        onSelect={onSelect}
                        onSearch={onSearchSelect}
                        placeholder='Cities, Regions'
                        value={city}
                        showSearch={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
            <div
              className={
                'Campaigns-body' +
                ((!campaigns || campainsLength === 0) && !isCampaignsLoading
                  ? ' all-center'
                  : '')
              }
            >
              {isCampaignsLoading ? (
                [1, 2, 3, 4].map((index) => (
                  <div
                    key={`skeleton-${index}`}
                    className='CampaignCard skeleton-contanier'
                  >
                    <Skeleton avatar active paragraph={{ rows: 4 }} />
                  </div>
                ))
              ) : campainsLength > 0 ? (
                campaigns?.map((campaign, i) => (
                  <CampaignCard
                    key={`Campaigns-card-${campaign.status}-${campaignType}-${i}`}
                    isBusinessUser={isBusinessUser}
                    campaignType={campaignType}
                    onJoin={() => {
                      handleCampaignJoin({
                        campaignPhoto: campaign.photoURL,
                        campaignName: campaign.title,
                        campaignOwner: campaign.businessName,
                        campaignPayPerScan: Number.parseFloat(
                          campaign.scanLimit.toFixed(2)
                        ),
                        QRtext: `${APP_URL}/show-campaign?data=${btoa(
                          JSON.stringify({
                            data0: campaign.id,
                            data1: user.data?.uid,
                          })
                        )}`,
                        /*QRtext: `${API_URL}/campaign/${campaign.id}/user/${user.data?.uid}/read`,*/
                      });
                    }}
                    onStart={handleCampaignStart}
                    onShowQR={() => {
                      handleCampaignJoin({
                        campaignPhoto: campaign.photoURL,
                        campaignName: campaign.title,
                        campaignOwner: campaign.businessName,
                        campaignPayPerScan: Number.parseFloat(
                          campaign.scanLimit.toFixed(2)
                        ),
                        QRtext:
                          user.data?.role && isBusinessUser
                            ? `${APP_URL}/share-campaign?data=${btoa(
                                campaign.id
                              )}`
                            : `${APP_URL}/show-campaign?data=${btoa(
                                JSON.stringify({
                                  data0: campaign.id,
                                  data1: user.data?.uid,
                                })
                              )}`,
                        /*QRtext: `${API_URL}/campaign/${campaign.id}/user/${user.data?.uid}/read`,*/
                      });
                    }}
                    {...campaign}
                  />
                ))
              ) : (
                <div className='Campaigns-no-campaigns'>
                  <img src={ImgNoCampaigns} />
                  <p>No campaigns yet</p>
                  {!isBusinessUser && (
                    <Button
                      className='Campaigns-search-button'
                      type='primary'
                      onClick={() => {
                        setCampaignType('find-campaign');
                        updateCampains({
                          campaignsType: 'find-campaign',
                        });
                      }}
                    >
                      <Icon name='search' fill='dark' /> Find campaigns
                    </Button>
                  )}
                </div>
              )}
            </div>

            <ScanQRModal
              modalVisibility={scanQRModalVisibility}
              campaignData={modalCampaignData}
              onModalDismiss={() => setScanQRModalVisibility(false)}
            />
          </BasicContentLayout>
        }
      />
    </div>
  );
};

export default memo(Campaigns);

import { memo, useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Col, Skeleton } from 'antd';
/* Components */
/* Services */
import { getCampaign } from '../../../services/campaign';
/* Files */
import HomeImg from '../../../assets/images/home-scanpanda.png';
/* Styles */
import './styles.scss';
import CampaignCard from '../../organisms/CampaignCard';
import { APP_URL } from '../../../constants';
import { Campaign, ModalCampaignDataSet } from '../../../types/campaigns';
import { auth } from '../../../credentials/base';
import ScanQRModal from '../../organisms/Modals/ScanQRModal';
import AuthTemplate from '../../templates/AuthTemplate';

const ShareCampaign = (): JSX.Element => {
  const [campaign, setCampaign] = useState<Campaign>();
  const [messageType, setMessageType] = useState<string>('loading');
  const [campaignType, setCampaignType] = useState<string>('all');
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(true);
  const [isJoined, setIsJoined] = useState(false);
  const urlQuery = new URLSearchParams(useLocation().search);
  const user = auth.currentUser;
  const [modalCampaignData, setModalCampaignData] =
    useState<ModalCampaignDataSet>({
      campaignPhoto: '',
      campaignName: '',
      campaignOwner: '',
      campaignPayPerScan: 0,
      QRtext: '',
    });
  const [scanQRModalVisibility, setScanQRModalVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      console.log('data slug', urlQuery.get('data'));
      try {
        const decodedData = atob(urlQuery.get('data') || '');
        if (decodedData) {
          const response = await getCampaign(decodedData /* campaignId */);
          if (response) {
            console.log('resdata', response);
            const resData = response.data;
            if (!response.error) {
              setCampaign({
                ...resData,
                id: decodedData,
                start_date: resData.startDate,
                end_date: resData.endDate,
              });
              setIsJoined(resData.isJoined);
              const campaignsType =
                resData.role === 'promoter'
                  ? resData.isJoined
                    ? 'share-joined'
                    : 'share-not-joined'
                  : 'all';
              setCampaignType(campaignsType);
              setIsCampaignsLoading(false);
            } else {
              setMessageType('invalid');
              setIsCampaignsLoading(false);
            }
          } else {
            setMessageType('invalid');
            setIsCampaignsLoading(false);
          }
        } else {
          setMessageType('invalid');
          setIsCampaignsLoading(false);
        }
      } catch {
        setMessageType('invalid');
        setIsCampaignsLoading(false);
      }
    })();
  }, [isJoined, campaignType]);

  const handleCampaignJoin = useCallback(
    (campaignData: ModalCampaignDataSet): void => {
      setModalCampaignData(() => {
        return {
          ...campaignData,
        };
      });
      setScanQRModalVisibility(true);
    },
    [setScanQRModalVisibility]
  );

  return (
    <div className='HomeBusiness'>
      <AuthTemplate
        mainContent={
          <div className='HomeBusiness-content'>
            <Row justify='center' className='HomeBusiness-main-row'>
              <div className='campaign-container'>
                {isCampaignsLoading ? (
                  <>
                    <div className='campaign-title'>
                      <Skeleton active paragraph={{ rows: 1 }} />
                    </div>
                    <div className='CampaignCard skeleton-contanier'>
                      <Skeleton avatar active paragraph={{ rows: 4 }} />
                    </div>
                  </>
                ) : campaign ? (
                  <>
                    <h2 className='campaign-title'>{campaign.businessName}</h2>
                    <CampaignCard
                      isBusinessUser={false}
                      campaignType={campaignType}
                      onJoin={() => {
                        handleCampaignJoin({
                          campaignPhoto: campaign.photoURL,
                          campaignName: campaign.title,
                          campaignOwner: campaign.businessName,
                          campaignPayPerScan: Number.parseFloat(
                            campaign.scanLimit.toFixed(2)
                          ),
                          QRtext: `${APP_URL}/show-campaign?data=${btoa(
                            JSON.stringify({
                              data0: campaign.id,
                              data1: user?.uid,
                            })
                          )}`,
                          /*QRtext: `${API_URL}/campaign/${campaign.id}/user/${user.data?.uid}/read`,*/
                        });
                      }}
                      onStart={() => ''}
                      onShowQR={() => {
                        handleCampaignJoin({
                          campaignPhoto: campaign.photoURL,
                          campaignName: campaign.title,
                          campaignOwner: campaign.businessName,
                          campaignPayPerScan: Number.parseFloat(
                            campaign.scanLimit.toFixed(2)
                          ),
                          QRtext: `${APP_URL}/show-campaign?data=${btoa(
                            JSON.stringify({
                              data0: campaign.id,
                              data1: user?.uid,
                            })
                          )}`,
                          /*QRtext: `${API_URL}/campaign/${campaign.id}/user/${user.data?.uid}/read`,*/
                        });
                      }}
                      {...campaign}
                      isJoined={isJoined}
                      setIsJoined={setIsJoined}
                      setCampaignType={setCampaignType}
                    />
                    <ScanQRModal
                      modalVisibility={scanQRModalVisibility}
                      campaignData={modalCampaignData}
                      onModalDismiss={() => setScanQRModalVisibility(false)}
                    />
                  </>
                ) : (
                  <>
                    <Col xs={24} sm={24} md={12}>
                      <h1 className=''>
                        {messageType === 'invalid' && (
                          <>
                            <span className='accent'>Oops!</span>This campaign
                            is not valid
                          </>
                        )}
                      </h1>
                      <Button className='outline'>
                        <Link to='/'>Home</Link>
                      </Button>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <img className='w-100' src={HomeImg} />
                    </Col>
                  </>
                )}
              </div>
            </Row>
          </div>
        }
      />
    </div>
  );
};

export default memo(ShareCampaign);

import { memo, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Skeleton, Form, Row, Col, Input, Select } from 'antd';
/* Components */
import CampaignCard from '../../organisms/CampaignCard';
import SimpleHeader from '../../organisms/SimpleHeader';
/* Types */
import { Campaign } from '../../../types/campaigns';
/* Context */
import { useCampaignProvider } from '../../../context/Campaigns';
/* Constants */
/* Files */
import ImgNoCampaigns from '../../../assets/images/no-campaigns.svg';
/* Styles */
import './styles.scss';
import moment from 'moment';
import { useSearchCampaigns } from '../../../hooks/useSearchCampaigns';
import { useUserContex } from '../../../context/User';
import Logo from '../../atoms/Logo';

const Landing = (): JSX.Element => {
  const [campaigns, setCampaigns] = useState<Array<Campaign>>([]);
  const [sort, setSort] = useState<string>('recent');
  const history = useHistory();
  const [form] = Form.useForm();
  const { user } = useUserContex();
  const isPromoterUser = user.data?.role == 'promoter';
  const { Search } = Input;
  const { Option } = Select;
  const {
    city,
    setSearchText,
    setCityQuery,
    setCity,
    options,
    searchText,
    searchCampaigns,
  } = useSearchCampaigns();

  const { isLoading: isCampaignsLoading, campaigns: providerCampaigns } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useCampaignProvider()!;
  const campainsLength = campaigns.length;

  useEffect(() => {
    handleSort(sort, providerCampaigns);
    setCampaigns(providerCampaigns);
  }, [providerCampaigns]);

  useEffect(() => {
    if (searchCampaigns) searchCampaigns(city, searchText);
  }, []);

  const onSearch = (val: string) => {
    setCityQuery(val);
  };

  const handleSort = (value: string, campaigns: Array<Campaign>) => {
    setSort(value);
    if (value === 'budget-lowest') {
      campaigns.sort((a, b) => a.budget - b.budget);
      setCampaigns([...campaigns]);
    } else if (value === 'budget-highest') {
      campaigns.sort((a, b) => b.budget - a.budget);
      setCampaigns([...campaigns]);
    } else {
      campaigns.sort((a, b) => moment(b.start_date).diff(a.start_date));
      setCampaigns([...campaigns]);
    }
  };

  const onSelect = (val: string) => {
    setCity(val);
  };

  const campaignType = 'all';

  return (
    <div className='Campaigns'>
      <Row justify='center' align='middle'>
        <div className='Button-container-auth'>
          <div className='Container-auth'>
            <Logo type={'default'} />
          </div>
          <div className='Container-auth'>
            <Link to='/login'>
              <Button size='small'>Log In</Button>
            </Link>
          </div>
          <div className='Container-auth'>
            <Link to='/sign-up'>
              <Button size='small'>Sign Up</Button>
            </Link>
          </div>
        </div>
      </Row>
      <SimpleHeader />
      <div className='Landing-campaigns-container'>
        <div className='Landing-campaigns-header'>
          <div className='Campaigns-title'>Campaigns</div>

          <Row justify='space-around' align='middle'>
            <Button
              className='Landing-btn-create-campaign'
              type='primary'
              onClick={() => history.push('/sign-up/business')}
            >
              Create campaign
            </Button>
          </Row>
        </div>
        <Form
          className='Landing-form'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name='basic'
          onFinish={() => ''}
          onFinishFailed={() => ''}
          onValuesChange={() => ''}
          form={form}
          initialValues={{
            landingPage: 'https://sendcustomerhere.com',
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={7}>
              <Form.Item name='title'>
                <Search
                  placeholder='Search brands, keyword, etc'
                  onSearch={(value) => setSearchText(value)}
                  size='large'
                  style={{ height: '40px' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={5}>
              <Select
                defaultValue={sort}
                onChange={(value) => handleSort(value, campaigns)}
                style={{ width: '100%', marginBottom: '24px' }}
                size={'large'}
              >
                <Option value='recent'>Most Recent</Option>
                <Option value='budget-highest'>
                  Budget: Highest to Lowest
                </Option>
                <Option value='budget-lowest'>Budget: Lowest to Highest</Option>
              </Select>
            </Col>
            <Col xs={24} sm={4}>
              <Form.Item
                name='city'
                rules={[
                  { required: false, message: 'Please input city or region!' },
                  { type: 'string', message: 'Please, enter a valid city!' },
                ]}
              >
                <Select
                  options={options}
                  onSelect={onSelect}
                  onSearch={onSearch}
                  placeholder='Cities, Regions'
                  value={city}
                  showSearch={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div
          className={
            'Landing-campaigns-body' +
            ((!campaigns || campainsLength === 0) && !isCampaignsLoading
              ? ' all-center'
              : '')
          }
        >
          {isCampaignsLoading ? (
            [1, 2, 3, 4].map((index) => (
              <div
                key={`skeleton-${index}`}
                className='CampaignCard skeleton-contanier'
              >
                <Skeleton avatar active paragraph={{ rows: 4 }} />
              </div>
            ))
          ) : campainsLength > 0 ? (
            campaigns?.map((campaign, i) => (
              <CampaignCard
                key={`Campaigns-card-${campaign.status}-${i}`}
                isBusinessUser={false}
                isPromoterUser={isPromoterUser}
                campaignType={campaignType}
                style={{ marginRight: 0 }}
                onJoin={() => ''}
                onStart={() => ''}
                onShowQR={() => ''}
                {...campaign}
              />
            ))
          ) : (
            <div className='Campaigns-no-campaigns-landing'>
              <img src={ImgNoCampaigns} />
              <p>No campaigns yet</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Landing);

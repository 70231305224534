import { memo, useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
/* Components */
import DashboardTemplate from '../../templates/DashboardTemplate';
import WOMCampaignForm from '../../organisms/WordOfMmouthCampaignForm';
import Icon from '../../atoms/Icon';
import StripeConnectModal from '../../organisms/Modals/StripeConnectModal';
import PaymentModal from '../../organisms/Modals/PaymentModal';
import { openNotification } from '../../../utils';
/* Services */
import { createCampaign } from '../../../services/campaign';

/* Styles */
import './styles.scss';
const calculateCampaignFunds = (budget: number): number =>
  Math.round(((budget - 0.3) / 1.1) * 100) / 100;

const NewWordOfMmouthCampaign = (): JSX.Element => {
  const [paymentModalVisibility, setPaymentModalVisibility] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stripeConnectModalVisibility, setStripeConnectModalVisibility] =
    useState<boolean>(false);
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuccessSubmit = useCallback(
    async (values: any, callBack: (res: any) => void) => {
      const payload = { ...values };
      payload.citiesRegions = {
        city: values.city,
        lat: values.lat,
        lng: values.lng,
      };
      delete payload.city;
      payload.budget = calculateCampaignFunds(values.budget);
      payload.makePublic = values.makePublic;

      const createResponse = await createCampaign({ ...payload });
      if (!createResponse.error) {
        openNotification(
          'success',
          'Create campaign',
          'Campaign created successfully.'
        );
        callBack({ cid: createResponse.data.key });
      } else {
        callBack({ error: createResponse.error });
      }
    },
    []
  );

  const handleSuccessStripeConnect = () => {
    setPaymentModalVisibility(true);
  };

  const handleSuccessPayment = () => {
    setPaymentModalVisibility(false);
    history.push('/campaigns');
  };

  return (
    <div className='NewWoMCampaign'>
      <DashboardTemplate
        mainContent={
          <div className='card-wrapper'>
            <div className='wow-card'>
              <span className='close-action'>
                <Link to='/campaigns'>
                  <Icon name='close' />
                </Link>
              </span>
              <h2 className='wow-card-title'>
                Create New Word of Mouth Campaign
              </h2>

              <WOMCampaignForm onSuccessSubmit={handleSuccessSubmit} />

              <StripeConnectModal
                modalVisibility={stripeConnectModalVisibility}
                onSuccessConnect={handleSuccessStripeConnect}
              />
              <PaymentModal
                modalVisibility={paymentModalVisibility}
                onSuccessPayment={handleSuccessPayment}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default memo(NewWordOfMmouthCampaign);

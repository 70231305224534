import { memo, Fragment } from 'react';
import { Layout } from 'antd';
import { landingTemplateBgMode } from './../../../types';
import './styles.scss';

const { Header, Content } = Layout;

const LandingTemplate = ({
  header,
  mainContent,
  bgMode,
}: {
  header: string | JSX.Element;
  mainContent: string | JSX.Element;
  bgMode?: landingTemplateBgMode;
}): JSX.Element => {
  const bgModeClass: landingTemplateBgMode = bgMode ? bgMode : 'light';
  return (
    <Fragment>
      <Layout className={bgModeClass}>
        <div className='dark-background'></div>
        <Header>{header}</Header>
        <Content>{mainContent}</Content>
      </Layout>
    </Fragment>
  );
};

export default memo(LandingTemplate);

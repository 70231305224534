import { memo, useCallback, useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import LabeledInput from '../../atoms/LabeledInput';

import './styles.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const creditcardutils = require('creditcardutils');

const PaymentForm = ({ onPayNow }: { onPayNow?: () => void }): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = useCallback((values: any): void => {
    console.log('Success:', values);
    if (typeof onPayNow === 'function') {
      onPayNow();
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = useCallback((errorInfo: any): void => {
    console.log('Failed:', errorInfo);
    if (typeof onPayNow === 'function') {
      onPayNow();
    }
  }, []);

  type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];
  const tipsCVV = 'Tip: A CVV is usually 3 to 4 numbers long';
  const tipsCard = 'Tip: A card number is not always 16 numbers long';

  const [form] = Form.useForm();
  const [cardType, setCardType] = useState('null');

  function validateCard13Number(cardNumber: string): {
    validateStatus: ValidateStatus;
    errorMsg: string | null;
  } {
    if (creditcardutils.parseCardType(cardNumber)) {
      setCardType(creditcardutils.parseCardType(cardNumber));
    } else {
      setCardType('null');
    }

    if (cardNumber.length < 13) {
      return {
        validateStatus: 'error',
        errorMsg: 'A card number is minimum 13 digits long',
      };
    }
    const signsRegex = /[\,._`'"\-*+]+/; //not including space
    const hasSigns = signsRegex.test(cardNumber);

    if (hasSigns)
      return {
        validateStatus: 'error',
        errorMsg: 'Please input only numbers!',
      };
    const cardOk = creditcardutils.validateCardNumber(cardNumber);
    if (cardOk) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
    return {
      validateStatus: 'error',
      errorMsg: 'Make sure you entered the numbers correctly',
    };
  }

  function validateCVVNumber(CVVNumber: string): {
    validateStatus: ValidateStatus;
    errorMsg: string | null;
  } {
    if (CVVNumber.length < 3) {
      return {
        validateStatus: 'error',
        errorMsg: 'A CVV is minimum 3 digits long',
      };
    }
    const signsRegex = /[\/ ,._`'"\-*+]+/;
    const hasSigns = signsRegex.test(CVVNumber);
    if (!Number(CVVNumber) || hasSigns)
      return {
        validateStatus: 'error',
        errorMsg: 'Please input only numbers!',
      };

    const CVVRegex = /^\d{3,4}$/;
    const CVVOk = CVVRegex.test(CVVNumber);
    if (CVVOk && creditcardutils.validateCardCVC(CVVNumber, cardType)) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    } else {
      if (CVVNumber.length == 3) {
        return {
          validateStatus: 'error',
          errorMsg:
            'Your card is a ' +
            cardType +
            ' and the CVV should be 4 digits long',
        };
      } else {
        return {
          validateStatus: 'error',
          errorMsg:
            'Your card is a ' +
            cardType +
            ' and the CVV should be 3 digits long',
        };
      }
    }

    return {
      validateStatus: 'error',
      errorMsg: 'A CVV is 3 to 4 numbers long',
    };
  }

  const [cardNumber, setCardNumber] = useState<{
    value: string;
    validateStatus?: ValidateStatus;
    errorMsg?: string | null;
  }>({
    value: '',
  });

  const [CVVNumber, setCVVNumber] = useState<{
    value: string;
    validateStatus?: ValidateStatus;
    errorMsg?: string | null;
  }>({
    value: '',
  });

  const onCardNumberChange = (value: string): boolean => {
    const trimmedValue = value.replaceAll(' ', '');
    if (trimmedValue.length > 19) return false;
    if (
      !Number(trimmedValue) &&
      trimmedValue.length > 0 &&
      Number(trimmedValue) !== 0
    )
      return false;

    const creditCardNumberChars = value.replaceAll(' ', '').split('');
    const creditCardNumber = creditCardNumberChars.length
      ? creditCardNumberChars.reduce((accumulator, currentValue, index) => {
          if ((index + 1) % 4 === 0 && index !== 0 && index !== 15) {
            return accumulator + currentValue + ' ';
          } else {
            return accumulator + currentValue;
          }
        })
      : '';

    setCardNumber(() => {
      return {
        ...validateCard13Number(creditCardNumber),
        value: creditCardNumber,
      };
    });
    return true;
  };

  const onCVVNumberChange = (value: string): boolean => {
    const CVVRegex = /[\/ ,._`'"\-*+]+/;
    const CVVOk = CVVRegex.test(value);
    if (value.length > 4) return false;
    if (!Number(value) && CVVOk && value.length > 0 && Number(value) !== 0)
      return false;
    const creditCardNumberChars = value.replaceAll(' ', '');
    setCVVNumber(() => {
      return {
        ...validateCVVNumber(creditCardNumberChars),
        value: creditCardNumberChars,
      };
    });
    return true;
  };

  return (
    <div className='PaymentForm'>
      <Form
        id='payment-form'
        form={form}
        labelCol={{ span: 24 }}
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        wrapperCol={{ span: 24 }}
      >
        <div className='payment-methods'>
          <Button className='mt-2' type='primary' htmlType='button'>
            Credit card
          </Button>
          <Button className='mt-2' type='default' htmlType='button'>
            Bank Account
          </Button>
        </div>
        <div className='fieldset-form'>
          <div className='fieldset-header'>
            <h3>Credit Card</h3>
          </div>
          <div className='fieldset-inputs'>
            <Form.Item
              name='name-on-card'
              rules={[
                {
                  required: false,
                  message: 'Please, input the name printed in the credit card!',
                },
                { type: 'string', message: 'Please, enter a valid name!' },
              ]}
            >
              <LabeledInput label='Name on card' />
            </Form.Item>
            <Row>
              <div id='containerEchizo'></div>
            </Row>
            <Row>
              <Col span={16}>
                <Form.Item
                  help={cardNumber.errorMsg || tipsCard}
                  name='cardNumber'
                  rules={[
                    {
                      required: true,
                      message: 'Please, enter your card number!',
                    },
                    { type: 'string', message: 'Please, enter only numbers!' },
                    {
                      len: 16,
                      message:
                        'A credit card number must have 16 numbers! (no spaces)',
                    },
                  ]}
                  validateStatus={cardNumber.validateStatus}
                >
                  <LabeledInput
                    label='Card number'
                    onValueChange={onCardNumberChange}
                    inputValue={cardNumber.value}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  help={CVVNumber.errorMsg || tipsCVV}
                  name='cc-code'
                  rules={[
                    {
                      required: true,
                      message: 'Please, input the CVV!',
                    },
                    { type: 'string', message: 'Please, enter only numbers!' },
                  ]}
                  validateStatus={CVVNumber.validateStatus}
                >
                  <LabeledInput
                    label='CVV'
                    onValueChange={onCVVNumberChange}
                    inputValue={CVVNumber.value}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <Form.Item>
          <Button className='mt-2' type='primary' htmlType='submit'>
            Pay Now
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default memo(PaymentForm);

//PROD
export const stripeApiKey =
  'pk_live_51JMye1FudfKcDDCm25ED3Als0Eb1UjKzrh3U5OV9ten0Lg6J8OGiGJ5t1P5xw5JN6H77oxoNGH9WHUEd6P1EjnnG00pucNASfq';
export const stripeEndPointSecret =
  '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';

//DEV
// export const stripeApiKey =
//   'pk_test_51JMye1FudfKcDDCmUlqyTCMTE8eR2BdfYVmoZnEUOhFuTIi2LDhTW2HYMrxIlp4tTfvjLk12NwEd9mIRav0SRgOI00rmGqhm04';
// export const stripeEndPointSecret =
//   'sk_test_51JMye1FudfKcDDCmWp3TOeB7tHTzyt7xSZjG5g53aMup2cFh6TugkMvaYpH8iWBvAUlVQCIjrvqgmDLTpUh370Jz00fcDMOkhD';

import { useState, useRef, useEffect, useCallback } from 'react';
import { auth, storage } from '../credentials/base';
import { updatePhoto } from '../services/user';
import { Point } from '../types';
import { resizeImage } from '../utils';

/**
 * Return a previous value of the param
 * @param {Object} value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function usePrevious(value: any): any {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Print in cosole a logger of prev and next state of data param
 * @param {Object} data to do logger
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function useLogger(data: any): void {
  const prevContext = usePrevious(data);
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      if (prevContext) {
        console.log('%cPrev Context', 'color: #ff008d');
        console.log(prevContext);
      }
      console.log('%cNex Context', 'color: #00d6f9');
      console.log(data);
    }
  }, [data]);
}

/**
 * Save initialValue param in local storange by key param
 * @param {string} key identifier of data to save
 * @param {any} initialValue data to save
 * @returns void
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function useLocalStorage(key: string, initialValue: any): Array<any> {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setValue = (value: any): void => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

/**
 * Join the useState hook with useRef to get a current value of the state
 * @param defaultValue
 * @returns array with state, setState and reference to the state
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function useStateRef(defaultValue: any) {
  const [state, setState] = useState(defaultValue);
  const ref = useRef(state);

  const dispatch = useCallback(function (val) {
    ref.current = typeof val === 'function' ? val(ref.current) : val;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
}

/**
 * Get the user location
 * @returns Point
 */
export async function useLocation(
  callback: (
    punto: Point | null,
    error: null | GeolocationPositionError
  ) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  if (!navigator.geolocation) return;
  navigator.geolocation.getCurrentPosition(
    function (position) {
      callback(
        {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        null
      );
    },
    function (error) {
      console.log(`ERROR CODE:', ${error.code} - ${error.message}`);
      callback(null, error);
    },
    { timeout: 5000 }
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function useSetPhotoUrl(): Array<any> {
  const [isUploading, setIsUploading] = useState(false);
  const [urlReload, setUrlReload] = useState<string | null>();

  const setPhotoUrl = async (file: File) => {
    // const [image, dot] = file.type.split('/');
    const config = {
      file: file,
      maxSize: 500,
    };
    console.log('ORIGINAL:', file.size);
    const resizedImage = await resizeImage(config);
    console.log('RESIZE:', resizeImage);
    setIsUploading(true);
    const user = auth.currentUser;
    const storageRef = storage.ref(`${user?.uid}/profilePicture/avatar.jpeg`);
    const uploadTask = storageRef.put(resizedImage);
    uploadTask.on(
      'state_changed',
      (snapShot: any) => {
        console.log('SnapShot:', snapShot);
      },
      (error: any) => {
        console.log('Error uploading picture:', error);
        setIsUploading(false);
      },
      () => {
        storageRef.getDownloadURL().then((downloadURL: any) => {
          user
            ?.updateProfile({
              photoURL: downloadURL,
            })
            .then(
              function () {
                updatePhoto({ photoURL: user.photoURL })
                  .then(() => {
                    setUrlReload(user.photoURL);
                    setIsUploading(false);
                  })
                  .catch((e) => console.log('error', e));
              },
              function (error: unknown) {
                console.log('error:', error);
                setIsUploading(false);
              }
            );
        });
      }
    );
  };

  return [isUploading, urlReload, setPhotoUrl];
}

import { memo } from 'react';
import { Skeleton } from 'antd';
import Logo from './../../../../atoms/Logo';

import './styles.scss';

const SideBarHeader = ({
  isLoading,
  userType,
}: {
  isLoading: boolean;
  userType?: string;
}): JSX.Element => {
  return (
    <div className='SideBarHeader'>
      <Logo type='black' />
      {isLoading ? (
        <Skeleton.Input
          className='user-type'
          active
          size='large'
          style={{ width: 100, height: 20, margin: '0 auto' }}
        />
      ) : (
        <span className='user-type'>{userType}</span>
      )}
      <hr className='separator-line' />
    </div>
  );
};

export default memo(SideBarHeader);

import { memo, useState, useCallback, useEffect } from 'react';
import { Modal } from 'antd';

import PaymentForm from '../../PaymentForm';

import './styles.scss';

const PaymentModal = ({
  modalVisibility = false,
  onModalDismiss,
  onSuccessPayment,
}: {
  modalVisibility?: boolean;
  onModalDismiss?: () => void;
  onSuccessPayment?: () => void;
}): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(modalVisibility);

  const handleOk = useCallback(() => {
    setIsModalVisible(false);

    if (typeof onSuccessPayment === 'function') {
      onSuccessPayment();
    }
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    if (typeof onModalDismiss === 'function') {
      onModalDismiss();
    }
  }, []);

  useEffect(() => {
    setIsModalVisible(modalVisibility);
  }, [modalVisibility]);

  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className='StripeConnectModal'>
        <h2>Payment Information</h2>
        <p>Select your payment method and pay</p>
        <PaymentForm onPayNow={handleOk} />
      </div>
    </Modal>
  );
};

export default memo(PaymentModal);

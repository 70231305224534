import { memo, useState, useCallback, useEffect } from 'react';
import { Modal } from 'antd';

import StripeForm from './../../StripeForm';

import './styles.scss';

const StripeConnectModal = ({
  modalVisibility = false,
  onModalDismiss,
  onSuccessConnect,
}: {
  modalVisibility?: boolean;
  onModalDismiss?: () => void;
  onSuccessConnect?: () => void;
}): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(modalVisibility);

  const handleOk = useCallback(() => {
    setIsModalVisible(false);

    if (typeof onSuccessConnect === 'function') {
      onSuccessConnect();
    }
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    if (typeof onModalDismiss === 'function') {
      onModalDismiss();
    }
  }, []);

  useEffect(() => {
    setIsModalVisible(modalVisibility);
  }, [modalVisibility]);

  return (
    <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
      <div className='StripeConnectModal'>
        <h2>Connect To Stripe</h2>
        <p>How much would you like to transfer?</p>
        <StripeForm onSuccess={handleOk} />
      </div>
    </Modal>
  );
};

export default memo(StripeConnectModal);

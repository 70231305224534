import axios, { AxiosError } from 'axios';
/* Utils */
import { openNotification } from '../utils';
/* Types */
import { Request, StorageData } from '../types';
/* Constants */
import { LOCAL_STORAGE_KEY } from '../constants';

const GetHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    let storedValue: string | null | StorageData =
      window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedValue) {
      storedValue = JSON.parse(storedValue) as StorageData;
      if (storedValue && storedValue.token)
        config.headers.Authorization = `Bearer ${storedValue.token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

async function apiCall({
  url,
  method = 'get',
  body,
  headers = {},
  options = {},
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Request): Promise<any> {
  const reqOptions = {
    headers: {
      ...GetHeaders,
      ...headers,
    },
    ...options,
  };
  let response;

  try {
    if (method === 'get') {
      response = await axiosInstance.get(url, reqOptions);
    } else {
      response = await axiosInstance[method](url, body, reqOptions);
    }
    return { data: response.data, status: response.status };
  } catch (error: any) {
    errorManager(url, error);
    return { error };
  }
}

const errorManager = (url: string, error: AxiosError): void => {
  if (url.search('/users/') !== -1 && error.response?.status === 401) {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    window.location.replace('/login');
  } else {
    openNotification('error', 'Error', error.response?.data.message);
  }
};

export default apiCall;
export { axiosInstance };

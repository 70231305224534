import { memo, useCallback } from 'react';
import { Form, Input, Button } from 'antd';

const SignUpBusinessForm = ({
  onSuccess,
}: {
  onSuccess: () => void;
}): JSX.Element => {
  const onFinish = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: any): void => {
      console.log('Success:', values);
      if (typeof onSuccess === 'function') onSuccess();
    },
    [onSuccess]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = useCallback((errorInfo: any): void => {
    console.log('Failed:', errorInfo);
  }, []);

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name='stripe-api-key'
        rules={[
          { required: true, message: 'Please input your Stripe API Key!' },
        ]}
      >
        <Input placeholder='Stripe API Key' />
      </Form.Item>

      <Form.Item
        name='stripe-private-key'
        rules={[
          {
            required: true,
            message: 'Please input the Stripe Private API Key!',
          },
        ]}
      >
        <Input.Password placeholder='Stripe Private API Key' />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Connect Stripe
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(SignUpBusinessForm);

import apiCall from './utils';
import { API_URL } from '../constants';
import { Campaign } from '../types/campaigns';

export interface RequestCampaign {
  campaigns: Array<Campaign>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createCampaign = async (values: Campaign): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/create-campaign/`,
    method: 'post',
    body: values,
  });
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCampaignCost = async (values: Campaign): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/payouts/precalculate`,
    method: 'post',
    body: values,
  });
  return response;
};

export const getCampaigns = async (
  city: string,
  searchText: string
): Promise<unknown> => {
  const response = await apiCall({
    url: `${API_URL}/campaigns-landing?city=${city}&searchText=${searchText}`,
    method: 'get',
    body: {},
  });
  return response.data;
};

export const updateCampainStatus = async (
  campaignId: string,
  status: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/campaigns/${campaignId}/status`,
    method: 'put',
    body: { status },
  });
  return response.data;
};

export const deleteCampaign = async (
  campaignId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/campaigns/${campaignId}/delete`,
    method: 'delete',
    body: {},
  });
  return response.data;
};

export const readCampain = async (
  campaignId: string,
  businessId: string,
  uid: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/campaign/${campaignId}/user/${businessId}/device/${uid}/read`,
    method: 'get',
    body: {},
  });
  return response;
};

export const getCampaign = async (
  campaignId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/campaign/${campaignId}`,
    method: 'get',
    body: {},
  });
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCities = async (query: string): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/cities?query=${query}`,
    method: 'get',
    body: {},
  });
  return response.data;
};

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
/* Routes */
import Routes from './routes';
/* Context */
import { UserProvider } from './context/User';
import { CampaignProvider } from './context/Campaigns';
/* Styles */
import './assets/styles/main.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { stripeApiKey } from './credentials/stripeCredentials';

const promise = loadStripe(stripeApiKey);
moment.locale('en');

function App(): JSX.Element {
  return (
    <Elements stripe={promise}>
      <ConfigProvider locale={enUS}>
        <UserProvider>
          <CampaignProvider>
            <Routes />
          </CampaignProvider>
        </UserProvider>
      </ConfigProvider>
    </Elements>
  );
}

export default App;

import { memo, useState, useCallback } from 'react';
import { Button, Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Link } from 'react-router-dom';

import './styles.scss';

const MenuHeader = ({
  type = 'default',
}: {
  type?: 'default' | 'sign-up' | undefined;
}): JSX.Element => {
  const [currentMenu, setCurrentMenu] = useState<string>('');

  const handleClick = useCallback((e: MenuInfo): void => {
    setCurrentMenu(e.key);
  }, []);

  return (
    <Menu
      className='MenuHeader'
      onClick={handleClick}
      selectedKeys={[currentMenu]}
      mode='horizontal'
    >
      {type == 'sign-up' && (
        <>
          <Menu.Item key='sign-up-personal' className='no-border-hover'>
            <Link to='/sign-up'>
              <div>For Promoter</div>
            </Link>
          </Menu.Item>
          <Menu.Item key='sign-up-business' className='no-border-hover'>
            <Link to='/sign-up/business'>
              <div>For Brands</div>
            </Link>
          </Menu.Item>
        </>
      )}
      <Menu.Item key='log-in' className='no-border-hover'>
        <Link to='/login'>
          <Button size='small'>Log In</Button>
        </Link>
      </Menu.Item>
      <Menu.Item key='sign-up' className='no-border-hover'>
        <Link to='/sign-up'>
          <Button size='small'>Sign Up</Button>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default memo(MenuHeader);

import { memo, useState, useCallback, useEffect } from 'react';
import { Modal } from 'antd';

import './styles.scss';

const PolicyModal = ({
  modalVisibility = false,
  onModalDismiss,
  onModalConfirm,
}: {
  modalVisibility?: boolean;
  onModalDismiss?: () => void;
  onModalConfirm?: () => void;
}): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(modalVisibility);

  const handleOk = useCallback(() => {
    setIsModalVisible(false);
    if (typeof onModalConfirm === 'function') {
      onModalConfirm();
    }
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    if (typeof onModalDismiss === 'function') {
      onModalDismiss();
    }
  }, []);

  useEffect(() => {
    setIsModalVisible(modalVisibility);
  }, [modalVisibility]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='Accept'
    >
      <div className='terms-conditions-accepting'>
        <h2>Almost There - Must be 18+</h2>
        <p className='py-3'>
          By register you are accepting our{' '}
          <a
            className='bold'
            href='https://scanpanda.org/terms-of-use/'
            target='_blank'
          >
            Terms of use,
          </a>{' '}
          <a
            className='bold'
            href='https://scanpanda.org/copyright-policy/'
            target='_blank'
          >
            Copyright
          </a>{' '}
          and the{' '}
          <a
            className='bold'
            href='https://scanpanda.org/privacy-policy-2/'
            target='_blank'
          >
            Privacy Policy
          </a>
          . Please Open and read each before continuing.
        </p>
      </div>
    </Modal>
  );
};

export default memo(PolicyModal);

import { memo, Fragment, useState } from 'react';
import { Layout } from 'antd';
import SideBar from '../../organisms/SideBar';
import './styles.scss';

const { Content } = Layout;

const DashboardTemplate = ({
  mainContent,
}: {
  mainContent: string | JSX.Element;
}): JSX.Element => {
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarToggle((prevState) => !prevState);
  };

  return (
    <Fragment>
      <Layout className='dashboard'>
        <SideBar
          collapsed={sidebarToggle}
          setCollapsed={(brp: boolean) => {
            setSidebarToggle(brp);
          }}
        />
        <Content>
          <div
            className={`menu-toogle-wrapper pl-5 pt-2 collapsed-${sidebarToggle}`}
          >
            <a onClick={toggleSidebar}>
              <img
                className='menu-toogle'
                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAAzklEQVRoge3YvQ3CMBCG4RdEyRLAAgRahoFBkJCggxUyDTVMkGQLOqSkgMJY+XGExDnoe6QrbLn4fI1PBhEREZHojGr21sD810F6KoBb24EdUA6ktm7wsXeRTVA/4tCaNQEy7LvdVRmw/KoNIiIiDn/WmgJ7YGaQpY8COAKPpgMX7F/t0Dq7wf1ZqwxtSWwm3voAPIGFQZY+cuBkHUJERP5XwuuxsR4/uip/Z22URhAytFI3uD9rXdtuGZmPrHVfpiuGMWvdrUOIiIiIdKgAK0vhhB7uSF4AAAAASUVORK5CYII='
              />
            </a>
          </div>
          {mainContent}
        </Content>
      </Layout>
    </Fragment>
  );
};

export default memo(DashboardTemplate);

import { Dropdown, Menu, Popconfirm } from 'antd';
import { memo } from 'react';
import { deleteCampaign } from '../../../../services/campaign';
import { openNotification } from '../../../../utils';
import Icon from '../../../atoms/Icon';
import './styles.scss';

interface HeaderContentProps {
  campaignType: string;
  isBusinessUser: boolean;
  id: string;
  onShowQR: () => void;
  handleUnjoin: () => void;
  setVisible: (value: boolean) => void;
}

const HeaderContent = ({
  campaignType,
  isBusinessUser,
  id,
  onShowQR,
  handleUnjoin,
  setVisible,
}: HeaderContentProps): JSX.Element => {
  const handleDeleteCampaing = (id: string) => {
    console.log('delete campaign', id);
    deleteCampaign(id)
      .then((response) => {
        if (!response.error) {
          setVisible(false);
          openNotification(
            'success',
            'Delete Campaign',
            'Campaign deleted successfully'
          );
        } else {
          openNotification(
            'error',
            'Delete Campaign',
            'Failed to delete campaign. An error has occurred: ' +
              response.message
          );
        }
      })
      .catch((error) => {
        console.log(error);
        openNotification(
          'error',
          'Delete Campaign',
          'Failed to delete campaign. An error has occurred: ' + error.message
        );
      });
  };

  const menu = (
    <Menu>
      <Menu.Item key={id + 'sahre'}>
        <div onClick={() => onShowQR()}>share URL</div>
      </Menu.Item>
      <Menu.Item key={id + 'delete'}>
        <Popconfirm
          title='Are you sure to delete this campaign?'
          onConfirm={() => handleDeleteCampaing(id)}
          okText='Yes'
          cancelText='No'
        >
          <span>
            <div>Delete</div>
          </span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='Campaign-content-header'>
      <Status isBusinessUser={isBusinessUser} campaignType={campaignType} />

      <div className='floating-bin'>
        {campaignType === 'my-campaigns' || campaignType === 'share-joined' ? (
          <Popconfirm
            title='Are you sure to unjoin this campaign?'
            onConfirm={handleUnjoin}
            okText='Yes'
            cancelText='No'
          >
            <span className='floating-bin'>
              <Icon size={24} name='trash' />
            </span>
          </Popconfirm>
        ) : (
          <></>
        )}
        {campaignType === 'play' && (
          <Dropdown overlay={menu} placement='bottomLeft'>
            <span className='floating-bin'>
              <Icon size={24} name='more-vertical' />
            </span>
          </Dropdown>
        )}
        {(campaignType === 'pause' || campaignType === 'expired') && (
          <Popconfirm
            title='Are you sure to delete this campaign?'
            onConfirm={() => handleDeleteCampaing(id)}
            okText='Yes'
            cancelText='No'
          >
            <span className='floating-bin'>
              <Icon size={24} name='trash' />
            </span>
          </Popconfirm>
        )}
      </div>
    </div>
  );
};

export default memo(HeaderContent);

interface StatusProps {
  isBusinessUser: boolean;
  campaignType: string;
}

const Status = ({ isBusinessUser, campaignType }: StatusProps): JSX.Element => {
  let label = '';
  if (isBusinessUser) {
    label =
      campaignType === 'expired' || campaignType === 'my-expired'
        ? 'Expired'
        : campaignType === 'play'
        ? 'Active'
        : 'Paused';
  } else {
    label = campaignType === 'my-expired' ? 'Expired' : ' ';
  }
  return <div className={`Campaign-status ${campaignType}`}> {label}</div>;
};

import { ReactNode } from 'react';
import './styles.scss';

const BasicContentLayout = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return <div className='BasicContentLayout'>{children}</div>;
};

export default BasicContentLayout;

import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
/* Services */
import { findCampaign as findCampaignBusiness } from '../services/business';
import {
  findCampaign as findCampaignPromoter,
  findCampaignByRadius,
} from '../services/promoter';
/* Hooks */
import { useLogger, useLocation } from '../hooks';
/* types */
import { Campaign } from '../types/campaigns';
import { Point } from '../types';
/* Files */
import { openNotification } from '../utils';
import { getCampaigns } from '../services/campaign';

interface CampaignContext {
  campaigns: Array<Campaign>;
  isLoading: boolean;
  loadCampaigns?: (type: string, status: string) => void;
  searchCampaigns?: (city: string, searchText: string) => void;
  updateCampain?: (campaignId: string, status: string) => void;
}

const Context = createContext<CampaignContext>({
  campaigns: [],
  isLoading: false,
});
const { Provider, Consumer } = Context;

const CampaignProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [campaigns, setCampaigns] = useState<Array<Campaign>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  useLogger({ campaigns, isLoading });

  const loadCampaigns = useCallback(async (type: string, status: string) => {
    let newCampaigns = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any;
    console.log('BREAK');
    switch (type) {
      case 'business':
        setLoading(true);
        response = await findCampaignBusiness(status);
        newCampaigns = getFormattedData(response);
        setCampaigns(newCampaigns);
        setLoading(false);
        break;
      case 'promoter-my':
        setLoading(true);
        response = await findCampaignPromoter(status);
        newCampaigns = getFormattedData(response);
        setCampaigns(newCampaigns);
        setLoading(false);
        break;
      case 'promoter-find':
        useLocation(async (location: Point | null, error) => {
          if (location && location.lat) {
            setLoading(true);
            response = await findCampaignByRadius(location?.lat, location?.lng);
            newCampaigns = getFormattedData(response);
            setCampaigns(newCampaigns.filter((campaign) => !campaign.isJoined));
            setLoading(false);
          } else {
            setCampaigns([]);
            openNotification(
              'warning',
              'Find Campaigns',
              "Couldn't find campaigns. Please check locations permissions in browser and/or computer."
            );
          }
        });
        break;
    }
  }, []);

  const searchCampaigns = useCallback(
    async (city: string, searchText: string) => {
      setLoading(true);
      const response = await getCampaigns(city, searchText);
      const newCampaigns = getFormattedData(response);
      setCampaigns(newCampaigns.filter((campaign) => !campaign.isJoined));
      setLoading(false);
    },
    []
  );

  const updateCampain = useCallback(
    (campaignId: string, status?: string) => {
      setCampaigns((campaigns) => {
        const newCampaigns: Array<Campaign> = [];
        for (let index = 0; index < campaigns.length; index++) {
          const campaign = campaigns[index];
          if (campaign.id === campaignId) {
            if (!status) {
              status = campaign.status;
            }
            newCampaigns.push({ ...campaign, status });
          } else {
            newCampaigns.push(campaign);
          }
        }
        return newCampaigns;
      });
    },
    [setCampaigns]
  );

  const value: CampaignContext = {
    campaigns,
    isLoading,
    loadCampaigns,
    searchCampaigns,
    updateCampain,
  };
  return <Provider value={value}>{children}</Provider>;
};

const useCampaignProvider = (): CampaignContext => useContext(Context);

export { CampaignProvider, Context, Consumer, useCampaignProvider };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFormattedData = (data: any): Array<Campaign> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data.map((campaign: any): Campaign => {
    console.log('getFormattedData', campaign);
    return {
      id: campaign.idCampaign,
      status: campaign.status,
      isJoined: campaign.isJoined,
      photoURL: campaign.photoURL,
      title: campaign.title,
      cost: campaign.cost,
      budget: campaign.budget,
      scanLimit: campaign.scanLimit,
      payment: campaign.payment,
      dailyPay: campaign.dailyPay,
      total: campaign.total,
      spent: campaign.budget - campaign.budgetRemaining,
      clicks: (campaign.budget - campaign.budgetRemaining) / campaign.scanLimit,
      description: campaign.description,
      start_date: campaign.startDate,
      end_date: campaign.endDate,
      expired: campaign.expired,
      businessName: campaign.businessName,
      makePublic: campaign.makePublic,
    };
  });
};

import { Link } from 'react-router-dom';
/* Files */
import siteLogo from '@/assets/images/scan-panda-logo.png';
/* Styles */
import './styles.scss';

const Logo = ({ type = 'default' }: { type?: string }): JSX.Element => {
  return (
    <Link to='/landing'>
      <div
        className={type == 'black' ? 'Logo-container black' : 'Logo-container'}
      >
        <img className='Logo-img' src={siteLogo} />
      </div>
    </Link>
  );
};

export default Logo;

import React, { useState, useEffect, memo } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './styles.scss';

interface StripePayload {
  cliSecret: string;
  value: number;
  onPaymentSucceed: (p?: boolean) => void;
  onPaymentFailed: () => void;
  onStatusChange: (status: boolean) => void;
}

const StripeCheckoutForm = ({
  cliSecret,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  onPaymentFailed,
  onStatusChange,
  onPaymentSucceed,
}: StripePayload) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [processing, setProcessing] = useState<boolean>(false);
  const [paid, setPaid] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    onStatusChange(!disabled && (paid || !(error && error.length)));
  }, [disabled, error, paid]);

  useEffect(() => {
    if (cliSecret.length) {
      handleSubmit();
    }
  }, [cliSecret]);

  const cardStyle = {
    style: {
      base: {
        color: '#000',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#000',
        },
      },
      invalid: {
        color: 'red',
        iconColor: 'red',
      },
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(!event.complete);
    setError(event.error ? event.error.message : '');
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async () => {
    if (!paid) {
      setProcessing(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const payload = await stripe.confirmCardPayment(cliSecret, {
        payment_method: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          card: elements.getElement(CardElement),
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
        onPaymentFailed();
        setPaid(false);
      } else {
        setError(null);
        onPaymentSucceed();
        setProcessing(false);
        setSucceeded(true);
        setPaid(true);
      }
    } else {
      setError(null);
      onPaymentSucceed(false);
      setProcessing(false);
      setSucceeded(true);
    }
  };
  return (
    <div id='payment-form' className='stripe-payment-form'>
      {!paid && (
        <CardElement
          id='card-element'
          options={cardStyle}
          onChange={handleChange}
        />
      )}

      {error && (
        <div className='card-error' role='alert'>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? 'result-message' : 'result-message hidden'}>
        Payment succeeded, see the result in your
        <a href={`https://dashboard.stripe.com/test/payments`}>
          {' '}
          Stripe dashboard.
        </a>{' '}
        Refresh the page to pay again.
      </p>
    </div>
  );
};
export default memo(StripeCheckoutForm);

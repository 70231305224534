import { memo } from 'react';
import { Link } from 'react-router-dom';
/* Components */
import Icon from './../../../../atoms/Icon';
/* Context */
import { useUserContex } from '../../../../../context/User';
/* Styles */
import './styles.scss';

const SideBarBottom = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { logout } = useUserContex()!;
  return null;
  return (
    <div className='SideBarBottom'>
      <div className='SideBarBottom-logout-icon' onClick={logout}>
        <Icon name='logout' fill='dark' />
      </div>
      <Link to='/wallet'>
        <Icon name='wallet' fill='dark' />
      </Link>
      <Link to='/profile'>
        <Icon name='settings' fill='dark' />
      </Link>
    </div>
  );
};

export default memo(SideBarBottom);

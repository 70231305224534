import { useEffect, useState } from 'react';
import { useUserContex } from '../../../context/User';
import logo from '@/assets/images/scan-panda-logo.png';

const ImageProfile = ({
  src,
  className,
  isBusinessUser = false,
}: {
  src?: string;
  className: string;
  isBusinessUser?: boolean;
}): JSX.Element => {
  const [urlPhoto, setUrlPhoto] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user } = useUserContex()!;

  useEffect(() => {
    if (isBusinessUser) {
      setUrlPhoto(user.data?.photoURL ? user.data?.photoURL : logo);
    } else {
      setUrlPhoto(src ? src : logo);
    }
    return () => setUrlPhoto('');
  }),
    [user.data?.photoURL];

  return (
    <div
      className={className}
      style={{
        backgroundImage: `url(${urlPhoto})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    ></div>
  );
};

export default ImageProfile;

import { memo, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { auth } from '../../../credentials/base';
import { openNotification } from '../../../utils';
/* Components */
import LandingTemplate from '../../templates/LandingTemplate';
import Header from '../../organisms/Header';
import SignInForm from '../../organisms/SignInForm';
import RecoverPassForm from '../../organisms/RecoverPassForm';
/* Context */
import { useUserContex } from '../../../context/User';
/* Files */
import siteLogo from '@/assets/images/user-panda.png';
import spinner from '@/assets/images/spinner.svg';
/* Styles */
import './styles.scss';
import { useState } from 'react';
import SplashScreen from '../../organisms/SplashScreen';

const SignIn = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user, login, isLoading, setIsLoading } = useUserContex()!;
  const history = useHistory();
  const location = useLocation();

  const [isRecoveryPassMode, setRecoveryPassMode] = useState<boolean>(false);
  const [headerTitle, setHeaderTitle] = useState<string>('Sign in');
  const [actionTitle, setActionTitle] = useState<string>(
    'I forgot my password'
  );

  useEffect(() => {
    if (user.authenticated) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.log('USER:', user.data?.role);
      const { from }: any = location.state || {
        from: {
          pathname:
            user.data?.role == 'business' ? '/campaigns/new' : '/campaigns',
        },
      };
      history.replace(from);
    }
  }, [user]);

  useEffect(() => {
    if (isRecoveryPassMode) {
      setHeaderTitle('Recovery password');
      setActionTitle('I want to sign in');
    } else {
      setHeaderTitle('Sign in');
      setActionTitle('I forgot my password');
    }
  }, [isRecoveryPassMode]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFormData = useCallback((values: any) => {
    login({ ...values });
  }, []);

  const handleRecoveryMode = () => {
    setRecoveryPassMode((prevState) => !prevState);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePasswordReset = useCallback((values: any) => {
    setIsLoading(true);
    auth
      .sendPasswordResetEmail(values.email, {
        url: `${window.location.origin}/login`,
      })
      .then(() => {
        setIsLoading(false);
        openNotification(
          'success',
          'Recover Password',
          'A recovery message was sent to your email.'
        );
        setRecoveryPassMode(false);
        console.log('Message sent');
      })
      .catch(() => {
        setIsLoading(false);
        openNotification('error', 'Recover Password', 'Email not found');
        console.error('An error has ocurred');
      });
  }, []);

  return (
    <div className='SignIn'>
      <LandingTemplate
        header={<Header />}
        mainContent={
          <div className='SignIn-form-container'>
            {isLoading && <SplashScreen />}

            {!isLoading && (
              <>
                <h2>{headerTitle}</h2>
                {!isRecoveryPassMode && (
                  <SignInForm getFormData={getFormData} isLoading={isLoading} />
                )}
                {isRecoveryPassMode && (
                  <RecoverPassForm
                    getFormData={handlePasswordReset}
                    isLoading={isLoading}
                  />
                )}
                <a
                  href='javascript:void(0)'
                  className='mt-4'
                  onClick={handleRecoveryMode}
                >
                  {actionTitle}
                </a>
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

export default memo(SignIn);

import apiCall from './utils';
import { API_URL } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findCampaign = async (status: string): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/business/campaigns/${status}`,
  });
  return response.data;
};

/* eslint-disable */
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import React, { memo, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
/* Components */
import DashboardTemplate from '../../templates/DashboardTemplate';

/* Styles */
import './styles.scss';
import BasicContentLayout from '../../templates/BasicContentLayout';
import Icon from '../../atoms/Icon';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { paymentAttempt } from '../../../services/stripe';

const PaymentForm = () => {
  const [cvc, setCvc] = useState<number>();
  const [expiry, setExpirity] = useState<Date>();
  const [focus, setFocus] = useState<number>();
  const [name, setName] = useState<string>();
  const [number, setNumber] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFinish = (values: any): void => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-empty-function
  const onFinishFailed = (errorInfo: any): void => {};

  // const handleInputFocus = (e: any) => {
  //   this['set' + e.target.name[0].toUpperCase() + e.target.name.splice(1)](
  //     e.target.name
  //   );
  // };
  //
  // handleInputChange = (e: any) => {
  //   console.log(e);
  //   const { name, value } = e.target;
  //   console.log(e.target);
  //   this.setState({ [name]: value });
  // };
  // // eslint-disable-next-line @typescript-eslint/no-empty-function
  // formValuesChange = () => {};
  //
  // render();
  // {
  //   return (
  //     <div className='NewPaymentMethod'>
  //       <DashboardTemplate
  //         mainContent={
  //           <BasicContentLayout>
  //             <div className='card-wrapper'>
  //               <div className='wow-card'>
  //                 <span className='close-action'>
  //                   <Link to='/business-wallet'>
  //                     <Icon name='close' />
  //                   </Link>
  //                 </span>
  //                 <div id='PaymentForm'>
  //                   <Cards
  //                     cvc={this.state.cvc}
  //                     expiry={this.state.expiry}
  //                     name={this.state.name}
  //                     number={this.state.number}
  //                   />
  //                   <Form
  //                     className='WOMCampaignForm'
  //                     labelCol={{ span: 24 }}
  //                     wrapperCol={{ span: 24 }}
  //                     name='basic'
  //                     onFinish={this.onFinish}
  //                     onFinishFailed={this.onFinishFailed}
  //                     onValuesChange={this.formValuesChange}
  //                     initialValues={{
  //                       landingPage: 'https://sendcustomerhere.com',
  //                     }}
  //                   >
  //                     <Row style={{ marginTop: '2rem' }}>
  //                       <Col span={24}>
  //                         <Form.Item
  //                           name='number'
  //                           rules={[
  //                             {
  //                               required: true,
  //                               message: 'Please enter the campaign title',
  //                             },
  //                             {
  //                               type: 'string',
  //                               message: 'Please, enter a valid title!',
  //                             },
  //                           ]}
  //                         >
  //                           <Input name='number' placeholder='Card Number' />
  //                         </Form.Item>
  //                       </Col>
  //                     </Row>
  //                   </Form>
  //                 </div>
  //               </div>
  //             </div>
  //           </BasicContentLayout>
  //         }
  //       />
  //     </div>
  //   );
  return null;
};
export default PaymentForm;

import apiCall from './utils';
import { API_URL } from '../constants';

// Create PaymentIntent as soon as the page loads
export const paymentAttempt = async (
  value: number,
  cid: string
): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/payment/create/`,
    method: 'post',
    body: {
      cid,
      items: { id: 'world-mouth-campaign', value },
    },
  });
  return response.data;
};

export const connectAttempt = async (): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/payment/onboard-user`,
    method: 'post',
  });
  return response.data;
};

export const connectRefreshAttempt = async (): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/payment/onboard-user/refresh`,
    method: 'post',
  });
  return response.data;
};

export const getPaymentMethods = async (): Promise<any> => {
  const response = await apiCall({
    url: `${API_URL}/payment/methods`,
    method: 'get',
  });
  return response.data;
};

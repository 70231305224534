import { Row, Col } from 'antd';
/* Components */
import WalletChart from '../PayoutsChart';
/* Types */
import { ChartConfig } from '../../../../../types/payouts';
/* Styles */
import './styles.scss';

const PayoutsCard = ({
  cardLabel,
  chartConfig1,
  chartConfig2,
}: {
  cardLabel: string;
  chartConfig1: ChartConfig;
  chartConfig2: ChartConfig;
}): JSX.Element => {
  return (
    <div className='Payouts-card white-bg'>
      <Row justify='space-between'>
        <Col span={24}>
          <p className='Payouts-label'>{cardLabel}</p>
        </Col>
      </Row>
      <Row justify='space-around' className='Payouts-chart-container'>
        <Col xs={24} sm={24} md={12} className='Payouts-chart'>
          <WalletChart {...chartConfig1} />
        </Col>
        <Col xs={24} sm={24} md={12} className='Payouts-chart'>
          <WalletChart {...chartConfig2} />
        </Col>
      </Row>
    </div>
  );
};

export default PayoutsCard;

import { Fragment, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
/* Components */
import DashboardTemplate from '../../templates/DashboardTemplate';

/* Styles */
import './styles.scss';
import BasicContentLayout from '../../templates/BasicContentLayout';
import { Button } from 'antd';
import { getPaymentMethods } from '../../../services/stripe';

const BusinessWallet = (): JSX.Element => {
  const [methods, setMethods] = useState<Array<any>>([]);
  const setMethodsHandler = (methodsArr: any) => {
    setMethods(Object.keys(methodsArr).map((k: string) => methodsArr[k]));
    console.log(methods);
  };
  const handleGetPayments = (): void => {
    getPaymentMethods().then((data) => {
      setMethodsHandler(data);
    });
  };
  useEffect(() => {
    handleGetPayments();
  }, []);

  return (
    <div className='BusinessWallet'>
      <DashboardTemplate
        mainContent={
          <BasicContentLayout>
            <div className='payment-methods-header'>
              <h2>Payment Methods</h2>

              <Fragment>
                <div>
                  <Link to='/payment/new-method'>
                    <Button type='primary'>Create new</Button>
                  </Link>
                </div>
              </Fragment>
            </div>
            <div>test</div>
          </BasicContentLayout>
        }
      />
    </div>
  );
};

export default memo(BusinessWallet);

import { memo } from 'react';
/* Components */
import SideBarHeader from './molecules/SideBarHeader';
import SideBarMenu from './molecules/SideBarMenu';
import SideBarBottom from './molecules/SideBarBottom';
import SideBarUser from './molecules/SideBarUser';
import { Layout } from 'antd';
/* Context */
import { useUserContex } from '../../../context/User';
/* Files */
import './styles.scss';

const { Sider } = Layout;

const SideBar = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed?: (bp: boolean) => void;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user, isLoading } = useUserContex()!;
  const userMode = user.data?.role === 'business' ? 'BRANDS' : 'PROMOTER';

  return (
    <Sider
      className='sidebar'
      breakpoint={'lg'}
      theme='dark'
      collapsedWidth={0}
      trigger={null}
      collapsed={collapsed}
      onBreakpoint={(broken) => {
        if (setCollapsed) {
          setCollapsed(broken);
        }
      }}
    >
      <SideBarHeader isLoading={isLoading} userType={userMode} />
      <SideBarUser
        isLoading={isLoading}
        userPhoto={user.data?.photoURL}
        userName={user.data?.displayName}
        userType={userMode}
      />
      <SideBarMenu isLoading={isLoading} userType={userMode} />
      <SideBarBottom />
    </Sider>
  );
};

export default memo(SideBar);

import { memo, Fragment, useState } from 'react';
import './styles.scss';
import DashboardTemplate from '../DashboardTemplate';
import Header from '../../organisms/Header';
import LandingTemplate from '../LandingTemplate';
import { auth } from '../../../credentials/base';

const AuthTemplate = ({
  mainContent,
}: {
  mainContent: string | JSX.Element;
}): JSX.Element => {
  return (
    <Fragment>
      {auth.currentUser ? (
        <DashboardTemplate mainContent={mainContent} />
      ) : (
        <LandingTemplate
          header={<Header type='onlyLogo' />}
          mainContent={mainContent}
        />
      )}
    </Fragment>
  );
};

export default memo(AuthTemplate);

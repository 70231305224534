//production
export const API_URL =
  'https://us-central1-scanpanda-344af.cloudfunctions.net/api';
export const APP_URL = 'https://irepbrands.com';

//dev
// export const API_URL =
//   'https://us-central1-scanpanda-dev.cloudfunctions.net/api';
// export const APP_URL = 'https://scanpanda-dev.web.app/';

//local
// export const API_URL = 'http://localhost:5002/scanpanda-dev/us-central1/api';
// export const APP_URL = 'http://localhost:3000';

export const LOCAL_STORAGE_KEY = 'Scan-panda-data';

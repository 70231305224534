import { Doughnut } from 'react-chartjs-2';
/* Types */
import { ChartConfig } from '../../../../../types/payouts';
/* Styles */
import './styles.scss';

const WalletChart = ({
  label,
  value,
  valueClass,
  data,
  options,
  pattern,
}: ChartConfig): JSX.Element => {
  return (
    <div className='PayoutsChart'>
      <p className='Payouts-chart-label gray text-center'>{label}</p>
      <div className='Payouts-Doughnut-wrapper'>
        <p className={`Payouts-Doughnut-text ${valueClass}`}>{value}</p>
        <Doughnut data={data} options={options} />
        <img className='Payouts-Doughnut-pattern' src={pattern} />
      </div>
    </div>
  );
};

export default WalletChart;
